export const gradeProblemTypeMapping = {
    addition: {
        first: ['oneDigitAddition'],
        second: ['oneDigitAddition', 'twoDigitAddition'],
        third: ['oneDigitAddition', 'twoDigitAddition', 'threeDigitAddition'],
        fourth: ['oneDigitAddition', 'twoDigitAddition', 'threeDigitAddition'],
    },
    subtraction: {
        second: ['oneDigitSubtraction'],
        third: ['oneDigitSubtraction', 'twoDigitSubtraction', 'threeDigitSubtraction'],
        fourth: ['oneDigitSubtraction', 'twoDigitSubtraction', 'threeDigitSubtraction'],
    },
    multiplication: {
        third: ['oneDigitMultiplication', 'twoDigitMultiplication'],
        fourth: ['oneDigitMultiplication', 'twoDigitMultiplication', 'threeDigitMultiplication'],
    },
    division: {
        third: ['division'],
        fourth: ['division'],
    },
    clock: {
        first: ['clockProblem'],
        second: ['clockProblem'],
        third: ['clockProblem'],
        fourth: ['clockProblem'],
    },
};


const MY_CONSTANTS = {
    CLOCK_TYPE: "clock",
    MULTIPLICATION_TYPE: "x",
    SUBTRACTION_TYPE: "-",
    ADDITION_TYPE: "+",
    DIVISION_TYPE: "/",
    PRINT_LENGTH_20: 20,
    PRINT_LENGTH_50: 50,
    PRINT_LENGTH_100: 100,
    DEFAULT_LIMIT: 20,
    DEFAULT_FACTOR: 10,
};

export default MY_CONSTANTS;