import React from 'react';
import ProblemsGrid from './ProblemsGrid';
import Header from './Header';
import Affirmation from './Affirmation';
import './Worksheet.css'

const Worksheet = ({ problems }) => {
  return (
    <div className='w-full'>
      <p className='no-print text-2xl'>Print Worksheet</p>
      <Header />
      <Affirmation />
      <ProblemsGrid problems={problems} />
    </div>
  )
}

export default Worksheet