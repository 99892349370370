import React, { useState, useRef, useEffect } from 'react';

const AnswerInput = ({ problemType, userAnswer, setUserAnswer, onKeyDown }) => {
    const [inputValue, setInputValue] = useState(''); // Tracks user input
    const inputRef = useRef(null); // Reference to the input field

    // Sync local state with parent prop
    useEffect(() => {
        setInputValue(userAnswer);
        if (inputRef.current) {
            inputRef.current.focus(); // Refocus input when userAnswer changes
        }
    }, [userAnswer]);


    // Format the input for clock problems
    const formatInput = (input) => {
        const cleanInput = input.replace(/\D/g, ''); // Remove non-numeric characters
        const paddedInput = cleanInput.padStart(4, '0'); // Ensure 4 digits with placeholders
        return `${paddedInput.slice(0, 2)}:${paddedInput.slice(2, 4)}`;
    };

    const handleInputChange = (e) => {
        let rawInput = e.target.value.replace(/\D/g, ''); // Strip non-numeric characters

        // Handle clock-specific formatting
        if (problemType === 'clock') {
            // Ensure the input is limited to 4 characters
            if (rawInput.length > 4) {
                rawInput = rawInput.slice(-4); // Keep only the last 4 digits
            }

            // Pad start with zeros if less than 4 digits
            rawInput = rawInput.padStart(4, '0');

            setInputValue(rawInput); // Update local input state
            setUserAnswer(formatInput(rawInput)); // Format and pass to parent state
        } else {
            // For other problem types, just update the input as-is
            setInputValue(rawInput);
            setUserAnswer(rawInput);
        }
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus(); // Ensure input is focused
        }
    }, []);

    return (
        <div className="relative w-full max-w-xs">
            <input
                ref={inputRef}
                type="tel"
                data-testid="answer-input"
                aria-label='Answer input'
                value={problemType === 'clock' ? formatInput(inputValue) : inputValue}
                onChange={handleInputChange}
                onKeyDown={onKeyDown}
                className="relative w-full text-center text-black text-3xl border-2 rounded-md focus:outline-none focus:border-indigo-500"
                autoFocus
                style={{
                    fontFamily: 'monospace',
                    letterSpacing: '2px',
                    caretColor: 'black',
                }}
            />
        </div>);
};

export default AnswerInput;