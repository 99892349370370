// QandAReducer.js
import { debugLog } from '../utils';

export const initialState = {
    dynamicProblems: [],
    currentProblemIndex: 0,
    questionStates: [],
    session: {
        ended: false,
        mode: null, // 'endless', 'timed', or 'bounded'
        startTime: null,
        elapsedTime: 0,
        outcome: null,
    },
    progress: {
        totalQuestions: 0,
        answered: 0,
        skipped: 0,
        submissions: 0,
    },
};

function QandAReducer(state = initialState, action) {
    debugLog('Reducer invoked with action:', action);

    switch (action.type) {
        case 'SET_PROBLEMS': {
            const { problems, mode } = action.payload || {};
            if (!problems || !Array.isArray(problems)) {
                console.error('SET_PROBLEMS action must have a valid problems array.');
                return state; // Return current state if problems are invalid
            }

            const questionStates = problems.map(() => ({
                status: 'unanswered',
                attempts: 0,
                correct: null,
            }));

            const newState = {
                ...state,
                dynamicProblems: problems,
                questionStates,
                progress: {
                    totalQuestions: problems.length,
                    answered: 0,
                    skipped: 0,
                    submissions: 0,
                },
                currentProblemIndex: 0,
                session: { ...state.session, ended: false, mode },
            };
            debugLog("Next state after SET_PROBLEMS: ", JSON.stringify(newState, null, 2));
            return newState;
        }

        case 'START_SESSION': {
            const { startTime } = action.payload;
            const newState = {
                ...state,
                session: {
                    ...state.session,
                    startTime,
                    ended: false,
                    outcome: null,
                },
            };
            debugLog("Next state after START_SESSION: ", JSON.stringify(newState, null, 2));
            return newState;
        }

        case 'ANSWER_QUESTION': {
            const { index, submittedAnswer, isCorrect } = action.payload;

            if (state.session.ended) return state;

            const updatedQuestionStates = [...state.questionStates];
            const prevStatus = updatedQuestionStates[index].status;

            updatedQuestionStates[index] = {
                ...updatedQuestionStates[index],
                status: 'answered',
                attempts: updatedQuestionStates[index].attempts + 1,
                correct: isCorrect,
            };

            const updatedProgress = {
                ...state.progress,
                answered: state.progress.answered + 1,
                submissions: state.progress.submissions + 1,
            };

            // Decrement skipped count if the question was previously skipped
            if (prevStatus === 'skipped') {
                updatedProgress.skipped -= 1;
            }

            // Update dynamicProblems to include userAnswer
            const updatedDynamicProblems = [...state.dynamicProblems];
            updatedDynamicProblems[index] = {
                ...updatedDynamicProblems[index],
                userAnswer: submittedAnswer,
            };

            const newState = {
                ...state,
                questionStates: updatedQuestionStates,
                dynamicProblems: updatedDynamicProblems,
                progress: updatedProgress,
            };
            debugLog("Next state after ANSWER_QUESTION: ", JSON.stringify(newState, null, 2));
            return newState;
        }

        case 'NEXT_QUESTION': {
            const { skip } = action.payload || {};
            const updatedQuestionStates = [...state.questionStates];
            const updatedProgress = { ...state.progress };

            if (skip) {
                updatedQuestionStates[state.currentProblemIndex] = {
                    ...updatedQuestionStates[state.currentProblemIndex],
                    status: 'skipped',
                };
                updatedProgress.skipped = (updatedProgress.skipped || 0) + 1;
            }

            let nextIndex = state.currentProblemIndex;
            let loopCount = 0;

            do {
                nextIndex = (nextIndex + 1) % state.dynamicProblems.length;
                loopCount++;

                if (loopCount > state.dynamicProblems.length) {
                    console.error('Circular navigation exhausted all problems.');
                    break;
                }
            } while (
                updatedQuestionStates[nextIndex]?.status === 'answered' ||
                (state.session.mode === 'endless' &&
                    updatedQuestionStates[nextIndex]?.status === 'skipped')
            );

            const newState = {
                ...state,
                questionStates: updatedQuestionStates,
                currentProblemIndex: nextIndex,
                progress: updatedProgress,
            };
            debugLog("Next state after NEXT_QUESTION: ", JSON.stringify(newState, null, 2));
            return newState;
        }

        case 'END_SESSION': {
            //const { elapsedTime, outcome } = action.payload;
            let unresolvedQuestions = state.questionStates
                .map((q, i) => (q.status === 'unanswered' ? i : null))
                .filter((i) => i !== null);

            // Exclude the last displayed question in endless mode
            if (state.session.mode === 'endless') {
                unresolvedQuestions = unresolvedQuestions.filter(
                    (i) => i !== state.currentProblemIndex
                );
            }

            const finalQuestionStates = [...state.questionStates];
            const updatedProblems = [...state.dynamicProblems];

            unresolvedQuestions.forEach((i) => {
                finalQuestionStates[i] = {
                    ...finalQuestionStates[i],
                    status: 'unanswered',
                };
                // Mark userAnswer as null for unanswered questions
                updatedProblems[i] = {
                    ...updatedProblems[i],
                    userAnswer: null,
                };
            });

            // For answered or skipped questions, ensure userAnswer is set
            finalQuestionStates.forEach((qState, i) => {
                if (qState.status === 'answered' && updatedProblems[i].userAnswer === undefined) {
                    updatedProblems[i] = {
                        ...updatedProblems[i],
                        userAnswer: 'some answer', // Replace with actual stored answer if available
                    };
                }
                if (qState.status === 'skipped' && updatedProblems[i].userAnswer === undefined) {
                    updatedProblems[i] = {
                        ...updatedProblems[i],
                        userAnswer: null,
                    };
                }
            });

            const { outcome = 'completed' } = action.payload || {};
            const { elapsedTime = 0 } = action.payload || {};
            const newState = {
                ...state,
                session: {
                    ...state.session,
                    ended: true,
                    outcome,
                    elapsedTime,
                },
                questionStates: finalQuestionStates,
                dynamicProblems: updatedProblems,
            };
            debugLog("Next state after END_SESSION: ", JSON.stringify(newState, null, 2));
            return newState;
        }

        case 'ADD_PROBLEM': {
            const { problem } = action.payload;
            const newState = {
                ...state,
                dynamicProblems: [...state.dynamicProblems, problem],
                questionStates: [
                    ...state.questionStates,
                    { status: 'unanswered', attempts: 0, correct: null },
                ],
                progress: {
                    ...state.progress,
                    totalQuestions: state.progress.totalQuestions + 1,
                },
                currentProblemIndex: state.dynamicProblems.length, // Point to the new problem
            };
            debugLog("Next state after ADD_PROBLEM: ", JSON.stringify(newState, null, 2));
            return newState;
        }

        default:
            console.warn('Unhandled action type:', action.type);
            return state;
    }
}

export default QandAReducer;