import React from 'react'
import affirmations from './affirmations';

const Affirmation = () => {

    const randomAffirmation = () => {
        const affirmation = affirmations.affirmations[Math.floor(Math.random() * affirmations.affirmations.length)]
        return affirmation
    };


    return (
        <div className='text-sm text-center py-1 m-1 italic invisible print-header'>
            Thought of the day: {randomAffirmation()}
        </div>
    )
}

export default Affirmation