import React from 'react';
import './LongDivision.css';

const LongDivisionProblem = ({ divisor, dividend }) => {
    return (
        <div className="long-division-container">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 150 80"
                width="150"
                height="80"
                className="long-division-svg"
            >
                {/* Vinculum (horizontal line) */}
                <line
                    x1="30"
                    y1="20"
                    x2="90"
                    y2="20"
                    stroke="black"
                    strokeWidth="2"
                />

                {/* Bracket (vertical line) */}
                <line
                    x1="30"
                    y1="20"
                    x2="30"
                    y2="60"
                    stroke="black"
                    strokeWidth="2"
                />

                {/* Divisor Text */}
                <text
                    x="10"
                    y="45"
                    fontSize="12"
                    textAnchor="middle"
                    fontFamily="'Fira Mono', monospace"
                >
                    {divisor}
                </text>

                {/* Dividend Text */}
                <text
                    x="55"
                    y="45"
                    fontSize="12"
                    textAnchor="middle"
                    fontFamily="'Fira Mono', monospace"
                >
                    {dividend}
                </text>
            </svg>
        </div>
    );
};

export default LongDivisionProblem;