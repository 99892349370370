import React from 'react';

const Hero = ({ theme }) => {
    const isKidified = theme === 'kidified'; // Check if theme is kidified to switch styles

    return (
        <div className="w-full  no-print relative">
            {isKidified ? (
                // Kidified FunHero
                <div className="w-full h-auto bg-slate-700 p-6 md:p-8 border-4 rounded-md border-fuchsia-500 relative">

                    {/* Using Flexbox for alignment */}
                    <div className="flex items-center justify-between w-full h-full">
                        {/* Left Star Icon */}
                        <div className="text-4xl md:text-5xl text-white hover:scale-105 transition-transform">
                            &#9733; {/* Star symbol */}
                        </div>

                        {/* Text content in the center */}
                        <div className="text-white flex flex-col items-center justify-center flex-grow px-4 md:px-12">
                            <h1 className="font-schoolbell font-bold text-2xl md:text-4xl lg:text-5xl drop-shadow-2xl text-center">
                                Math Problem Generator
                            </h1>
                            <p className="font-patrickhand max-w-full md:max-w-[600px] text-sm md:text-lg lg:text-xl text-center leading-relaxed break-words mt-4">
                                Use this to generate math worksheets for your kids. Select at least one or more problem types
                                from those available below. We're always adding more, so check back often. Suggestions welcome!
                            </p>
                        </div>

                        {/* Right Smiley Icon */}
                        <div className="text-4xl md:text-5xl text-white hover:scale-105 transition-transform">
                            &#9786; {/* Smiley face */}
                        </div>
                    </div>
                </div>
            ) : (
                // Adultified Hero
                <div className="relative w-full">
                    <img
                        className="top-20 left-0 w-full h-[40vh] object-cover no-print"
                        src="noita-digital-MdmcpX2ofRk-unsplash.jpg"
                        alt="Math Problem Generator"
                    />
                    <div className="absolute top-0 w-full h-[40vh] flex flex-col justify-center text-white">
                        <div className="md:left-[10%] max-w-[1100px] m-auto p-4">
                            <h1 className="font-bold text-4xl md:text-6xl drop-shadow-2xl text-center md:text-left">
                                Math Problem Generator
                            </h1>
                            <p className="max-w-[600px] drop-shadow-2xl py-4 text-lg text-center md:text-left">
                                Use this to generate math worksheets for your kids. Select at least one or more problem types
                                from those available below. We're always adding more, so check back often. Suggestions welcome!
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Hero;