import React, { useState } from "react";
import { FaClock, FaInfinity, FaListUl, FaPrint } from "react-icons/fa";
import { ReactComponent as BeadFrameSVG } from "../beadframe.svg";
import useIsMobile from '../hooks/useIsMobile';
import SelectDropdown from './SelectDropdown';
import GradeSelector from "./GradeSelector";

const QandAModeSelector = ({ onSelectMode }) => {
    const [selectedMode, setSelectedMode] = useState(null);
    const [problemCount, setProblemCount] = useState(10);
    const [timeLimit, setTimeLimit] = useState(300);
    const [difficulty, setDifficulty] = useState("easy");
    const [grade, setGrade] = useState("first");
    const [problemType, setProblemType] = useState("addition");
    const [printProblemCount, setPrintProblemCount] = useState(20);


    const isMobile = useIsMobile();

    const modes = [
        {
            name: "Timed",
            description: "Solve as many as you can before time runs out!",
            icon: <FaClock className="text-4xl text-blue-500" />,
            bgColor: "bg-blue-200",
            mode: "timed",
        },
        {
            name: "Endless",
            description: "Go on forever! Stop whenever you want.",
            icon: <FaInfinity className="text-4xl text-green-500" />,
            bgColor: "bg-green-200",
            mode: "endless",
        },
        {
            name: "Set Number",
            description: "Select the number of problems and aim for completion!",
            icon: <FaListUl className="text-4xl text-yellow-500" />,
            bgColor: "bg-yellow-200",
            mode: "bounded",
        },
        {
            name: "Bead Frame",
            description: "Use a bead frame to solve problems!",
            icon: <BeadFrameSVG className="text-4xl text-orange-500" width="40" height="40" />,
            bgColor: "bg-orange-200",
            mode: "bead",
        },
        {
            name: "Worksheet",
            description: "Print math worksheets!",
            icon: <FaPrint className="text-4xl text-pink-500" width="40" height="40" />,
            bgColor: "bg-pink-200",
            mode: "worksheet",
        },
    ];

    const handleStart = () => {
        if (selectedMode) {
            onSelectMode({
                mode: selectedMode,
                problemCount,
                timeLimit: selectedMode === "timed" ? timeLimit : undefined,
                difficulty: selectedMode === "bead" ? difficulty : undefined,
                printProblemCount: printProblemCount || 0,
                problemType: problemType || undefined,
                grade: grade || undefined,
            });
        }
    };

    const renderConfiguration = () => {
        if (selectedMode === "timed" || selectedMode === "bounded" || selectedMode === "bead") {
            return (
                <>
                    <label className="text-lg font-semibold mb-2">Number of Problems:</label>
                    <SelectDropdown
                        options={Array.from({ length: 50 }, (_, i) => ({
                            value: i + 1,
                            label: `${i + 1}`,
                        }))}
                        value={problemCount}
                        onChange={setProblemCount}
                        className="mb-4"
                    />
                </>
            );
        }
    };

    const modeConfig = {
        endless: {
            extraConfig: (
                <>
                    <GradeSelector onGradeChange={setGrade} problemType={problemType} onProblemTypeChange={setProblemType} />
                </>
            ),
        },
        bounded: {
            extraConfig: (
                <>
                    <GradeSelector onGradeChange={setGrade} problemType={problemType} onProblemTypeChange={setProblemType} />
                </>
            ),
        },
        timed: {
            extraConfig: (
                <>
                    <GradeSelector onGradeChange={setGrade} problemType={problemType} onProblemTypeChange={setProblemType} />

                    <label className="text-lg font-semibold mb-2">Time Limit (seconds):</label>
                    <SelectDropdown
                        options={[10, 20, 30, 60, 120, 300, 600].map((value) => ({
                            value,
                            label: `${value} seconds`,
                        }))}
                        value={timeLimit}
                        onChange={setTimeLimit}
                        className="mb-4"
                    />
                </>
            ),
        },
        bead: {
            extraConfig: (
                <>
                    <label className="text-lg font-semibold mb-2">Difficulty:</label>
                    <SelectDropdown
                        options={[
                            { value: "easy", label: "Easy" },
                            { value: "medium", label: "Medium" },
                            { value: "hard", label: "Hard" },
                        ]}
                        value={difficulty}
                        onChange={setDifficulty}
                        className="mb-4"
                    />
                </>
            ),
        },
        worksheet: {
            extraConfig: (
                <>
                    <GradeSelector onGradeChange={setGrade} problemType={problemType} onProblemTypeChange={setProblemType} />

                    <label className="text-lg font-semibold mb-2">Problems per Page:</label>
                    <SelectDropdown
                        options={[
                            { value: 20, label: "20" },
                            { value: 50, label: "50" },
                            { value: 100, label: "100" },
                        ]}
                        value={printProblemCount}
                        onChange={setPrintProblemCount}
                        className="mb-4"
                    />
                </>
            ),
        },
    };

    return (
        <div className="flex flex-col items-center p-4 gap-6">
            {/* Tile Selection */}
            <div className="flex flex-col md:flex-row gap-6 justify-center items-center">
                {modes.map((mode) => (
                    <div key={mode.name} className="w-full md:w-1/3">
                        <div
                            onClick={() => setSelectedMode(mode.mode)}
                            className={`${mode.bgColor} w-full p-6 rounded-lg shadow-lg cursor-pointer transition transform hover:scale-105 ${selectedMode === mode.mode ? "ring-4 ring-offset-2 ring-indigo-400" : ""
                                }`}
                        >
                            <div className="flex items-center justify-center mb-4">{mode.icon}</div>
                            <h3 className="text-xl font-semibold mb-2 text-center">{mode.name}</h3>
                            <p className="text-center text-gray-700">{mode.description}</p>
                        </div>

                        {/* Inline Configuration and Start Button for Mobile */}
                        {isMobile && selectedMode === mode.mode && (
                            <div className="flex flex-col items-center mt-4 w-full p-4 bg-white rounded-lg shadow-lg">
                                {(selectedMode === "bounded" || selectedMode === "bead" || selectedMode === "timed") && (
                                    <>
                                        {renderConfiguration()}
                                    </>
                                )}
                                {modeConfig[selectedMode]?.extraConfig}
                                <button
                                    onClick={handleStart}
                                    className="w-full bg-indigo-500 text-white p-3 rounded-lg shadow-lg text-center transition transform hover:scale-105"
                                >
                                    Start
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* Centralized Configuration Options and Start Button for Desktop */}
            {!isMobile && selectedMode && (
                <div className="flex flex-col items-center mt-4 w-full md:w-1/3 p-4 bg-white rounded-lg shadow-lg">
                    {(selectedMode === "bounded" || selectedMode === "bead" || selectedMode === "timed") && (
                        <>
                            {renderConfiguration()}
                        </>
                    )}
                    {modeConfig[selectedMode]?.extraConfig}
                    <button
                        onClick={handleStart}
                        className="w-full bg-indigo-500 text-white p-3 rounded-lg shadow-lg text-center transition transform hover:scale-105"
                    >
                        Start
                    </button>
                </div>
            )}
        </div>
    );
};

export default QandAModeSelector;