import React, { useEffect, useCallback } from 'react';
import ClockSVG from './ClockSVG';
import { default as mathC } from '../Constants';
import LongDivisionProblem from './LongDivisionProblems';

const ProblemsGrid = ({ problems }) => {
  const isEmpty = !Array.isArray(problems) || problems.length === 0;

  // Wrap `applyPrintClass` in `useCallback` to prevent redefinition on every render
  const applyPrintClass = useCallback(() => {
    if (isEmpty) return;

    const root = document.documentElement;
    root.classList.remove('print-20', 'print-50', 'print-100');
    if (problems.length === mathC.PRINT_LENGTH_20) {
      root.classList.add('print-20');
    } else if (problems.length === mathC.PRINT_LENGTH_50) {
      root.classList.add('print-50');
    } else if (problems.length === mathC.PRINT_LENGTH_100) {
      root.classList.add('print-100');
    }
  }, [isEmpty, problems]);

  useEffect(() => {
    if (!isEmpty) {
      applyPrintClass();
    }
  }, [isEmpty, applyPrintClass]);

  if (isEmpty) {
    return (
      <div className="text-center text-gray-700 text-lg my-8">
        No problems available. Please generate problems to get started.
      </div>
    );
  }

  let attr;
  switch (problems.length) {
    case mathC.PRINT_LENGTH_20:
      attr = "grid-cols-4 print-20";
      break;
    case mathC.PRINT_LENGTH_50:
      attr = "grid-cols-5 print-50";
      break;
    case mathC.PRINT_LENGTH_100:
      attr = "grid-cols-10 print-100";
      break;
    default:
      console.warn("unknown printing size");
      break;
  }

  return (
    <div className={`flex-grow grid ${attr} gap-0 problems-grid text-xl`}
      role='grid'>
      {problems.map((problem, index) => (
        <div key={index} data-testid={`problem-${index}`} className="problem flex flex-col content-start">
          {problem.type === mathC.CLOCK_TYPE ? (
            <ClockSVG time={problem.time} />
          ) : problem.type === mathC.DIVISION_TYPE ? (
            // Render the custom LongDivisionProblem for division
            <LongDivisionProblem dividend={problem.num1} divisor={problem.num2} />
          ) : (
            // For other problems, render them as before
            <div className="num1">{problem.num1}</div>
          )}
          <div className="operator-num2">
            {problem.type === mathC.CLOCK_TYPE || problem.type === mathC.DIVISION_TYPE ? (
              <span></span>
            ) : (
              <span>{problem.type}</span>
            )}
            {problem.type !== mathC.DIVISION_TYPE && <span>{problem.num2}</span>}
          </div>
          <div className="line-container">
            {problem.type === mathC.CLOCK_TYPE ? (
              <div className="clock-line"></div>
            ) : problem.type === mathC.DIVISION_TYPE ? (
              <div></div>
            ) : (
              <div className="line"></div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProblemsGrid;