import React, { useEffect, useState } from 'react';
import '../TimedModeTimer.css';

// Main component to render both the Progress Ring and Flip Clock
const TimedModeTimer = ({ timeLimit }) => {
    const [remainingTime, setRemainingTime] = useState(timeLimit);

    useEffect(() => {
        if (remainingTime > 0) {
            const timer = setInterval(() => setRemainingTime((prev) => prev - 1), 1000);
            return () => clearInterval(timer);
        }
    }, [remainingTime]);

    return (
        <div className="flex justify-center space-x-8">
            {/* <ProgressRing radius={50} stroke={8} remainingTime={remainingTime} timeLimit={timeLimit} /> */}
            <FlipClock remainingTime={remainingTime} />
        </div>
    );
};

// Animated Progress Ring
/* commenting out to avoid linter warning
const ProgressRing = ({ radius, stroke, remainingTime, timeLimit }) => {
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (remainingTime / timeLimit) * circumference;

    return (
        <svg height={radius * 2} width={radius * 2}>
            <circle
                stroke="#e0e0e0"
                fill="transparent"
                strokeWidth={stroke}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
            />
            <circle
                stroke="#4CAF50"
                fill="transparent"
                strokeWidth={stroke}
                strokeDasharray={circumference + ' ' + circumference}
                style={{ strokeDashoffset }}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
            />
            <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="24">
                {remainingTime}s
            </text>
        </svg>
    );
};
*/

// Digital Flip Clock
const FlipClock = ({ remainingTime }) => {
    const minutes = String(Math.floor(remainingTime / 60)).padStart(2, '0');
    const seconds = String(remainingTime % 60).padStart(2, '0');

    return (
        <div className="flip-clock space-x-2">
            <div className="flip-digit">{minutes[0]}</div>
            <div className="flip-digit">{minutes[1]}</div>
            <div className="colon">:</div>
            <div className="flip-digit">{seconds[0]}</div>
            <div className="flip-digit">{seconds[1]}</div>
        </div>
    );
};

export default TimedModeTimer;