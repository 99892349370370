import React, { useState, useEffect, useCallback, useReducer, useMemo, useRef, forwardRef, useImperativeHandle } from 'react';
import QandASummary from './QandASummary';
import ClockSVG from './ClockSVG';
import LongDivisionProblem from './LongDivisionProblems';
import { generateProblems as defaultGenerateProblems, getProblemTypesAndNames, generateProblemsWithDifficulty } from '../mathUtils';
import TimedModeTimer from './TimedModeTimer';
import AnswerInput from './AnswerInput';
import BeadFrame from './BeadFrame';
import Worksheet from './Worksheet';
import { default as mathC, gradeProblemTypeMapping } from '../Constants';
import useIsMobile from '../hooks/useIsMobile';
import QandAReducer, { initialState } from '../reducers/QandAReducer';
import { debugLog, debugWarn } from '../utils';

const QandA = forwardRef((props, ref) => {
    const { modeConfig, problems, onEndSession, generateProblems = defaultGenerateProblems, limit, factor, problemTypes, problemTypesAndNames } = props;

    const [state, dispatch] = useReducer(QandAReducer, initialState);
    const { progress, dynamicProblems, currentProblemIndex } = state;

    const [userAnswer, setUserAnswer] = useState('');
    const [beadFrameAnswer, setBeadFrameAnswer] = useState(0); // State to store bead frame answer
    const [resetBeadFrame, setResetBeadFrame] = useState(false);
    const [worksheetProblems, setWorksheetProblems] = useState();
    const [pTypes, setPTypes] = useState();
    const submitButtonRef = useRef(null);
    const skipButtonRef = useRef(null);

    const currentProblem = dynamicProblems.length > 0
        ? dynamicProblems[currentProblemIndex]
        : null;

        const gatherTroubleshootingData = useCallback(async () => {
            try {
                const troubleshootingData = {
                    sessionState: state.session,
                    progress: state.progress,
                    currentProblem,
                    dynamicProblems: dynamicProblems.length,
                    modeConfig,
                    userAnswer,
                    beadFrameAnswer,
                };
                return troubleshootingData;
            } catch (error) {
                console.error('Error gathering troubleshooting data:', error);
                return { error: 'Failed to collect troubleshooting data.' };
            }
        }, [state, currentProblem, dynamicProblems, modeConfig, userAnswer, beadFrameAnswer]);
    

    // Expose gatherTroubleshootingData via ref
    useImperativeHandle(ref, () => ({
        gatherTroubleshootingData,
    }));

    useEffect(() => {
        if (!modeConfig?.mode) return;

        const getProblemTypesForMode = (grade, problemType, mapping = gradeProblemTypeMapping) => {
            if (!grade || !problemType) {
                debugWarn('Invalid grade or problemType:', { grade, problemType });
                return {};
            }

            const defaultProblemTypes = {
                oneDigitAddition: false,
                twoDigitAddition: false,
                threeDigitAddition: false,
                oneDigitSubtraction: false,
                twoDigitSubtraction: false,
                threeDigitSubtraction: false,
                oneDigitMultiplication: false,
                twoDigitMultiplication: false,
                threeDigitMultiplication: false,
                division: false,
                timesTable: false,
                clockProblem: false,
            };

            const problemsForGrade = mapping[problemType]?.[grade] || [];

            if (!problemsForGrade.length) {
                debugWarn('No problem types found for:', { grade, problemType });
            }

            problemsForGrade.forEach((type) => {
                defaultProblemTypes[type] = true;
            });

            return defaultProblemTypes;
        };

        const generateWorksheetProblems = (modeConfig) => {
            const localProblemTypes = getProblemTypesForMode(
                modeConfig.grade,
                modeConfig.problemType,
                gradeProblemTypeMapping
            );

            const myProblemTypesAndNames = getProblemTypesAndNames(mathC.DEFAULT_LIMIT, mathC.DEFAULT_FACTOR);

            return generateProblems(
                localProblemTypes,
                modeConfig.printProblemCount || 20, // Default to 20 problems if not provided
                myProblemTypesAndNames,
                mathC.DEFAULT_LIMIT,
                mathC.DEFAULT_FACTOR
            );
        };

        const generateModeProblems = (modeConfig) => {
            const localProblemTypes = getProblemTypesForMode(
                modeConfig.grade,
                modeConfig.problemType,
                gradeProblemTypeMapping
            );

            const myProblemTypesAndNames = getProblemTypesAndNames(mathC.DEFAULT_LIMIT, mathC.DEFAULT_FACTOR);

            setPTypes(localProblemTypes);

            return generateProblems(
                localProblemTypes,
                modeConfig.mode === 'endless' ? 1 : modeConfig.problemCount || 10, // Default to 10 problems
                myProblemTypesAndNames,
                mathC.DEFAULT_LIMIT,
                mathC.DEFAULT_FACTOR
            );
        };

        const generateBeadModeProblems = () => {
            const localProblemTypes = {
                oneDigitAddition: false,
                twoDigitAddition: false,
                threeDigitAddition: false,
                oneDigitSubtraction: true,
                twoDigitSubtraction: false,
                threeDigitSubtraction: false,
            };

            const localProblemTypesAndNames = {
                oneDigitAddition: { text: "1-Digit Addition", digits: 1, type: mathC.ADDITION_TYPE },
                twoDigitAddition: { text: "2-Digit Addition", digits: 2, type: mathC.ADDITION_TYPE },
                threeDigitAddition: { text: "3-Digit Addition", digits: 3, type: mathC.ADDITION_TYPE },
                oneDigitSubtraction: { text: "1-Digit Subtraction", digits: 1, type: mathC.SUBTRACTION_TYPE },
                twoDigitSubtraction: { text: "2-Digit Subtraction", digits: 2, type: mathC.SUBTRACTION_TYPE },
                threeDigitSubtraction: { text: "3-Digit Subtraction", digits: 3, type: mathC.SUBTRACTION_TYPE },
            };

            switch (modeConfig?.difficulty) {
                case 'easy':
                    localProblemTypes.oneDigitAddition = true;
                    localProblemTypes.oneDigitSubtraction = true;
                    break;
                case 'medium':
                    localProblemTypes.oneDigitAddition = true;
                    localProblemTypes.twoDigitAddition = true;
                    localProblemTypes.oneDigitSubtraction = true;
                    localProblemTypes.twoDigitSubtraction = true;
                    break;
                default:
                    localProblemTypes.oneDigitAddition = true;
                    localProblemTypes.twoDigitAddition = true;
                    localProblemTypes.threeDigitAddition = true;
                    localProblemTypes.oneDigitSubtraction = true;
                    localProblemTypes.twoDigitSubtraction = true;
                    localProblemTypes.threeDigitSubtraction = true;
                    break;
            }

            return generateProblemsWithDifficulty(
                localProblemTypes,
                modeConfig.problemCount,
                localProblemTypesAndNames,
                mathC.DEFAULT_LIMIT,
                modeConfig.difficulty
            );
        };

        const generateDefaultProblems = () => {
            return generateProblems(
                problemTypes,
                modeConfig.mode === 'endless' && state.dynamicProblems.length === 0 ? 1 : modeConfig.problemCount || 0,
                problemTypesAndNames,
                mathC.DEFAULT_LIMIT,
                mathC.DEFAULT_FACTOR
            );
        };

        const setProblems = (generatedProblems) => {
            if (generatedProblems?.length) {
                dispatch({
                    type: 'SET_PROBLEMS',
                    payload: { problems: generatedProblems, mode: modeConfig.mode },
                });
            } else if (state.dynamicProblems.length === 0) {
                console.error('Failed to generate problems: No problems were returned.');
            }
        };

        const isInitialProblemLoad = (mode) =>
            state.dynamicProblems.length === 0 && modeConfig.mode === mode;

        if (isInitialProblemLoad('bead')) {
            // Generate problems for bead mode only when no problems exist
            const beadProblems = generateBeadModeProblems();
            setProblems(beadProblems);
            debugLog("bead frame problems");
        } else if (isInitialProblemLoad('worksheet')) {
            //const modeConfig = { grade: 'third', problemType: 'addition' };
            const probs = generateWorksheetProblems(modeConfig);
            setWorksheetProblems(probs);
            setProblems(probs);  // this is a hack to get the problems to display
            debugLog("worksheet problems");
        } else if ((modeConfig.mode === 'timed' || modeConfig.mode === 'endless' || modeConfig.mode === 'bounded') && state.dynamicProblems.length === 0) {
            const probs = generateModeProblems(modeConfig);
            setProblems(probs);
            debugLog("generate endless || timed || bounded problems");
        } else if (state.dynamicProblems.length === 0) {
            // Generate problems for other modes when no problems exist
            const defaultProblems = generateDefaultProblems();
            setProblems(defaultProblems);
            debugLog("other problems");
        }
    }, [modeConfig, problemTypes, problemTypesAndNames, worksheetProblems, generateProblems, limit, factor, state.dynamicProblems]);

    // set the start time for all modes
    useEffect(() => {
        const timeNow = Date.now();
        dispatch({
            type: 'START_SESSION',
            payload: {
                startTime: timeNow, // or new Date().getTime()
            },
        });
        debugLog("set startTime: ", timeNow);

    }, [modeConfig?.mode]);

    const isMobile = useIsMobile();

    const progressPercentage = dynamicProblems?.length
        ? (progress.answered / dynamicProblems.length) * 100
        : 0;

    // Ends the session, handling different behavior for Endless Mode.
    // In Endless Mode, removes the last problem to prevent an incomplete entry
    // from appearing as "skipped" in the summary, then sets sessionEnded to true.
    // In other modes, simply sets sessionEnded to true without modifying the problem array.
    // Finally, triggers the onEndSession callback (if provided) to initiate any additional end-session logic.
    const handleEndSession = useCallback((message) => {
        debugLog('handleEndSession invoked at:', new Date().toISOString());

        // Prevent redundant session end
        if (state.sessionEnded) {
            debugLog('Session already ended, skipping onEndSession call');
            return;
        }

        // Dispatch END_SESSION action
        dispatch({
            type: 'END_SESSION',
            payload: {
                mode: modeConfig.mode,
                outcome: message,
                elapsedTime: state.session.startTime
                    ? Math.floor((Date.now() - state.session.startTime) / 1000)
                    : null,
            },
        });

        debugLog("elapsedTime: ", Math.floor((Date.now() - state.session.startTime) / 1000));

        // Trigger onEndSession callback
        debugLog("Dispatching END_SESSION completed, calling onEndSession");
        onEndSession && onEndSession();
    }, [dispatch, state.sessionEnded, modeConfig, onEndSession, state.session.startTime]);

    const setupTimedMode = useCallback(() => {
        if (modeConfig.mode !== 'timed' || state.session.ended || modeConfig.timeLimit <= 0) {
            return null;
        }

        return setTimeout(() => {
            try {
                handleEndSession("timerExpired");
            } catch (error) {
                console.error('Error ending session:', error);
            }
        }, modeConfig.timeLimit * 1000);
    }, [modeConfig, state.session.ended, handleEndSession]);

    useEffect(() => {
        const timer = setupTimedMode();
        return () => clearTimeout(timer);
    }, [setupTimedMode]);


    const handleAnswerSubmit = useCallback(() => {
        debugLog('handleAnswerSubmit called');
        debugLog('sessionEnded:', state.session.ended);

        // Exit early if the session has ended
        if (state.session.ended) return;

        const currentAnswer = userAnswer.trim();
        debugLog('currentAnswer:', currentAnswer);

        const currentProblem = state.dynamicProblems[state.currentProblemIndex];
        if (!currentProblem) {
            debugWarn('No current problem found.');
            return;
        }
        debugLog('currentProblem:', currentProblem);

        // Helper function to format and validate time answers
        const formatTimeInput = (timeString) => {
            try {
                const cleanedTime = timeString.replace(/^_+/, '');
                const [hours, minutes] = cleanedTime.split(':').map((part) => part.padStart(2, '0'));
                return `${hours}:${minutes}`;
            } catch (error) {
                debugWarn('Invalid time format:', timeString);
                return null; // Return null for invalid formats
            }
        };

        // Determine the submitted answer
        let submittedAnswer =
            modeConfig.mode === 'bead' ? beadFrameAnswer : currentAnswer || null;

        // Determine if the submitted answer is correct
        let isCorrect = false;

        if (currentProblem.type === 'clock') {
            const formattedAnswer = formatTimeInput(submittedAnswer || '');
            const expectedTime = formatTimeInput(currentProblem.time);
            isCorrect = formattedAnswer === expectedTime;
        } else {
            isCorrect = submittedAnswer?.toString() === currentProblem.answer.toString();
        }

        debugLog('submittedAnswer:', submittedAnswer, 'isCorrect:', isCorrect);

        // Dispatch the ANSWER_QUESTION action
        dispatch({
            type: 'ANSWER_QUESTION',
            payload: {
                index: state.currentProblemIndex,
                submittedAnswer,
                isCorrect,
                solution: currentProblem.answer.toString(), // TODO i don't think this does anything
            },
        });

        // Clear input and reset the bead frame if applicable
        setUserAnswer('');
        if (modeConfig.mode === 'bead') {
            setResetBeadFrame(true);
        }

        // Handle Endless Mode
        if (modeConfig.mode === 'endless') {
            // Add a new problem
            const myProblemTypesAndNames = getProblemTypesAndNames(mathC.DEFAULT_LIMIT, mathC.DEFAULT_FACTOR);
            // handle endless also (override problem count)

            const newProblem = generateProblems(
                pTypes,
                1,
                myProblemTypesAndNames,
                mathC.DEFAULT_LIMIT,
                mathC.DEFAULT_FACTOR)[0];

            if (newProblem) {
                dispatch({ type: 'ADD_PROBLEM', payload: { problem: newProblem } });
            }
            // Move to next question
            dispatch({ type: 'NEXT_QUESTION' });
        } else {
            // For other modes, check if it's the last question
            const isLastQuestion = state.progress.answered + 1 === state.progress.totalQuestions;
            if (isLastQuestion) {
                handleEndSession("completed");
            } else {
                dispatch({ type: 'NEXT_QUESTION' });
            }
        }
    }, [
        state,
        pTypes,
        userAnswer,
        beadFrameAnswer,
        modeConfig.mode,
        dispatch,
        handleEndSession,
        generateProblems,
    ]);

    const handleSkip = () => {
        setUserAnswer('');
        if (modeConfig.mode === 'bead') {
            setResetBeadFrame(true);
        }
        // Dispatch NEXT_QUESTION with skip = true
        dispatch({
            type: 'NEXT_QUESTION',
            payload: { skip: true },
        });
    };

    // Render component only if the current problem exists
    // Retrieve current problem based on index only if `dynamicProblems` has elements
    // Validate the `problems` prop early

    const isLoading = useMemo(() => {
        return (
            !currentProblem &&
            !state.session.ended &&
            (!problems || !Array.isArray(problems))
        );
    }, [currentProblem, state.session.ended, problems]);

    /* 
    const isLoading = !currentProblem && !state.session.ended
        && (!problems || !Array.isArray(problems));
     */

    if (isLoading) {
        return (
            <div className="text-center text-gray-700 text-lg my-8">
                Loading problems...
            </div>
        );
    }

    if (!['timed', 'endless', 'bounded', 'bead', 'worksheet'].includes(modeConfig.mode)) {
        throw new Error(`Invalid mode: ${modeConfig.mode}`);
    }

    const renderInputButtons = () => {
        switch (modeConfig.mode) {
            case 'bead':
            case 'timed':
            case 'bounded':
            case 'endless':
                return (
                    <>
                        {/* Buttons */}
                        <div className="button-container flex justify-center space-x-2 mt-4">
                            <button
                                data-testid="answer-submit-button"
                                ref={submitButtonRef}
                                onClick={handleAnswerSubmit}
                                className="submit-button px-4 py-2 bg-green-500 text-white rounded-full hover:bg-green-600"
                            >
                                Submit
                            </button>
                            <button
                                ref={skipButtonRef}
                                onClick={handleSkip}
                                className="skip-button px-4 py-2 bg-yellow-500 text-white rounded-full hover:bg-yellow-600"
                            >
                                Skip
                            </button>
                            {modeConfig.mode === 'endless' && (
                                <button
                                    onClick={() => handleEndSession('endless')}
                                    className="finish-session-button px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600"
                                >
                                    Finish Session
                                </button>
                            )}
                        </div>
                    </>
                );
            default:
                return null;
        }
    }

    const renderModeSpecificContent = () => {
        switch (modeConfig.mode) {
            case 'worksheet':
                return (
                    <div className="worksheet-container flex justify-center mb-4">
                        <Worksheet problems={worksheetProblems} />
                    </div>
                );
            case 'bead':
                return (
                    <div className="bead-frame-container flex justify-center mb-4">
                        <BeadFrame
                            problem={currentProblem}
                            initialValue={0}
                            onSubmitValue={(value) => setBeadFrameAnswer(value)}
                            resetBeadFrame={resetBeadFrame}
                            onResetComplete={() => setResetBeadFrame(false)}
                        />
                    </div>
                );
            case 'timed':
            case 'bounded':
            case 'endless':
                return (
                    <>
                        <div
                            data-testid="problem-display"
                            className={`problem-display flex justify-center mb-4 mt-4 ${currentProblem.type === 'clock'
                                ? 'text-lg'
                                : currentProblem.type === '/'
                                    ? 'text-xl'
                                    : 'text-3xl'
                                }`}
                        >
                            {currentProblem.type === 'clock' ? (
                                <ClockSVG time={currentProblem.time} />
                            ) : currentProblem.type === '/' ? (
                                <LongDivisionProblem
                                    dividend={currentProblem.num1}
                                    divisor={currentProblem.num2}
                                />
                            ) : (
                                <span>
                                    {currentProblem.num1} {currentProblem.type} {currentProblem.num2}
                                </span>
                            )}
                        </div>
                        <div className="input-area flex flex-col items-center mb-4">
                            <AnswerInput
                                key={currentProblem.type}
                                problemType={currentProblem.type}
                                userAnswer={userAnswer}
                                setUserAnswer={setUserAnswer}
                                onKeyDown={(e) => e.key === 'Enter' && handleAnswerSubmit()}
                            />
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className="flex w-full items-start">
            <div className="flex flex-col items-center flex-grow w-3/4">
                {/* Progress bar for timed, bounded, and bead modes */}
                {(modeConfig?.mode === 'timed' || modeConfig?.mode === 'bounded' || modeConfig?.mode === 'bead') && (
                    <div className="w-full px-8 my-4">
                        <div className="bg-gray-200 h-3 rounded-full overflow-hidden">
                            <div
                                className="h-full bg-green-500 rounded-full transition-all duration-300 ease-in-out"
                                style={{ width: `${progressPercentage}%` }}
                            />
                        </div>
                    </div>
                )}

                {/* Session Ended Case */}
                {state.session.ended ? (
                    <>
                        <QandASummary
                            problems={dynamicProblems}
                            elapsedTime={state.session.elapsedTime}
                            state={state}
                            modeConfig={modeConfig}
                        />
                    </>
                ) : (
                    // Active Problem Case
                    <>
                        {dynamicProblems.length > 0 ? (
                            <div className="problem-card max-w-4xl w-full px-4">
                                {renderModeSpecificContent()}

                                {renderInputButtons()}

                                {/* Timed Mode Timer */}
                                {modeConfig?.mode === 'timed' && !state.session.ended && (
                                    <div className={isMobile ? "timer-container mobile-timer" : "timer-container desktop-timer"}>
                                        <TimedModeTimer timeLimit={modeConfig.timeLimit} />
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </>
                )}
            </div>
        </div>
    );
});

export default QandA;