import React from 'react';

const ClockSVG = ({ time }) => {
  const width = 180;
  const height = width;
  const clockRadius = width * 0.4; // Clock outline radius
  const labelRadius = width * 0.33; // Label placement radius for text
  const cx = width / 2;
  const cy = height / 2;

  const [hours, minutes] = time.split(':')
  const hour = hours;
  const minute = minutes;

  const labels = [
    { hour: '1', degree: 30 },
    { hour: '2', degree: 60 },
    { hour: '3', degree: 90 },
    { hour: '4', degree: 120 },
    { hour: '5', degree: 150 },
    { hour: '6', degree: 180 },
    { hour: '7', degree: 210 },
    { hour: '8', degree: 240 },
    { hour: '9', degree: 270 },
    { hour: '10', degree: 300 },
    { hour: '11', degree: 330 },
    { hour: '12', degree: 0 }
  ];

  const calculateCoordinates = (degree) => {
    const radian = (degree - 90) * (Math.PI / 180);
    const x = cx + labelRadius * Math.cos(radian);
    const y = cy + labelRadius * Math.sin(radian);
    return { x, y };
  };

  // Calculate hand angles
  const hourAngle = (hour % 12) * 30 + minute * 0.5;
  const minuteAngle = minute * 6;

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} className="clock-problem"
    data-testid={'clock-svg'} data-time={time}>
      {/* Clock outline */}
      <circle cx={cx} cy={cy} r={clockRadius} stroke="black" strokeWidth="5" fill="none" />

      {/* Labels for the hours */}
      {labels.map(({ hour, degree }) => {
        const { x, y } = calculateCoordinates(degree);
        return (
          <text
            key={hour}
            x={x}
            y={y}
            fontSize={width * 0.08}
            textAnchor="middle"
            alignmentBaseline="middle"
          >
            {hour}
          </text>
        );
      })}

      {/* Hour hand */}
      <line
        x1={cx}
        y1={cy}
        x2={cx + (clockRadius * 0.45) * Math.cos((hourAngle - 90) * (Math.PI / 180))}
        y2={cy + (clockRadius * 0.45) * Math.sin((hourAngle - 90) * (Math.PI / 180))}
        stroke="black"
        strokeWidth="4"
      />

      {/* Minute hand */}
      <line
        x1={cx}
        y1={cy}
        x2={cx + (clockRadius * 0.65) * Math.cos((minuteAngle - 90) * (Math.PI / 180))}
        y2={cy + (clockRadius * 0.65) * Math.sin((minuteAngle - 90) * (Math.PI / 180))}
        stroke="black"
        strokeWidth="3"
      />

      {/* Center circle */}
      <circle cx={cx} cy={cy} r="4" fill="black" />
    </svg>
  );
};

export default ClockSVG;