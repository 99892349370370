import React from 'react';
import { FaXTwitter, FaArrowUpRightFromSquare, FaRegEnvelope } from 'react-icons/fa6';
import './Footer.css'; // Importing the new consolidated CSS file

const Footer = ({ theme }) => {
    const isKidified = theme === 'kidified'; // Check if theme is kidified to switch styles

    return (
        <div className={`footer ${isKidified ? 'kidified' : 'adultified'}`}>
            <div className="footer-grid">
                <div className="footer-left">
                    <p>&copy; 2024 Math Problem Generator. All rights reserved.</p>
                </div>
                <div className="footer-center">
                    <ol className="footer-links">
                        <li>
                            <a href="https://x.com/mymathsheets"
                                rel="noreferrer" target="_blank">
                                <FaXTwitter size={16} />
                                <FaArrowUpRightFromSquare color={isKidified ? "#d946ef" : "#d946ef"} size={12} />
                            </a>
                        </li>
                        <li>
                            <a href="mailto:hello@mymathsheets.com" target="_blank" rel="noreferrer"
                                className='hover:underline decoration-sky-500 hover:text-white flex no-print'
                            >
                                hello@mymathsheets.com
                                <FaRegEnvelope size={24} />
                            </a>
                        </li>
                    </ol>
                </div>
                <div className="footer-right">
                    <a href="https://mymathsheets.com" target="_blank" rel="noreferrer">
                        mymathsheets.com
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Footer;