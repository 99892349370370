const affirmations = {
    affirmations: [
      "I am perfect, just the way I am!",
      "When I get knocked down, I get back up.",
      "I believe in myself.",
      "My friends appreciate me.",
      "I can achieve anything I set my mind to.",
      "Challenges make me stronger.",
      "Kindness is my superpower.",
      "Mistakes help me learn and grow.",
      "I am unique and special.",
      "I can make a difference in the world.",
      "My feelings are important.",
      "Listening is one of my best skills.",
      "Creativity flows through me.",
      "Respect is something I give and receive.",
      "Confidence is my friend.",
      "Helping others makes me happy.",
      "I can handle anything that comes my way.",
      "Problems are opportunities to grow.",
      "I appreciate what I have.",
      "Patience brings good things.",
      "Positive energy surrounds me.",
      "Teamwork makes us stronger.",
      "I am someone people can rely on.",
      "Curiosity leads me to discover new things.",
      "Good manners open doors for me.",
      "I enjoy playing fairly.",
      "Caring for others is my nature.",
      "Communication is my strong suit.",
      "Adventure awaits me every day.",
      "Trust is built through honesty.",
      "Making good choices is my habit.",
      "Every day, I learn something new.",
      "Nature is a gift I cherish.",
      "Joy is my natural state.",
      "Sharing brings me joy.",
      "I think about how others feel.",
      "Achievements fill me with pride.",
      "There’s no one else like me.",
      "New experiences excite me.",
      "Doing my best is my goal.",
      "I contribute to my community.",
      "Family and friends are my treasures.",
      "My ideas are valuable.",
      "Reading opens up new worlds for me.",
      "Animals feel my kindness.",
      "Taking care of my health is important.",
      "Each day is an adventure.",
      "My intelligence shines through.",
      "Helping at home makes me happy.",
      "I show patience to myself and others.",
      "Gratitude fills my heart.",
      "I am my own best friend.",
      "Improvement is my daily goal.",
      "Gentleness is part of who I am.",
      "I follow instructions well.",
      "Setting a good example is important to me.",
      "I am friendly to everyone.",
      "Happiness starts with me.",
      "Listening and learning go hand in hand.",
      "My uniqueness is my strength.",
      "Empathy guides my actions.",
      "I am proud of my schoolwork.",
      "Love is all around me.",
      "I always lend a helping hand.",
      "Thinking deeply is my strength.",
      "Everyone's opinions matter, including mine.",
      "Learning new things excites me.",
      "My talents make me special.",
      "I look forward to new lessons.",
      "My culture and heritage are my pride.",
      "Being a friend is important to me.",
      "Potential is inside me.",
      "Thoughtfulness is my trademark.",
      "Kindness is my default mode.",
      "People in my life make me grateful.",
      "I set a good example for others.",
      "Hope fills my heart.",
      "My actions reflect my thoughts.",
      "I plan and organize with care.",
      "Being gentle with myself is important.",
      "Respecting my body is key.",
      "Being my best self is my goal.",
      "Thinking of others makes me happy.",
      "Health is a gift I treasure.",
      "Growth is a constant in my life.",
      "Working in teams brings out my best.",
      "The environment deserves my respect.",
      "Sharing brings joy to others and me.",
      "Respecting belongings is important.",
      "Standing up for what’s right takes courage.",
      "Enthusiasm brightens my day.",
      "Being a good friend means listening.",
      "Different perspectives enrich my life.",
      "Every new day is a blessing.",
      "Questions lead to knowledge.",
      "Leadership and followership both matter.",
      "Smiling spreads joy.",
      "Learning takes time and patience.",
      "Art and creativity are my outlets.",
      "Being me is my greatest strength!",
      "I am loved and cared for.",
      "I am a good friend.",
      "I am brave and strong.",
      "I am kind to others.",
      "I learn from my mistakes.",
      "I am proud of who I am.",
      "I am a great listener.",
      "I am creative and imaginative.",
      "I am respectful to everyone.",
      "I am helpful and considerate.",
      "I am a problem solver.",
      "I am grateful for all I have.",
      "I am full of positive energy.",
      "I am a great teammate.",
      "I am responsible and dependable.",
      "I am curious and eager to learn.",
      "I am polite and well-mannered.",
      "I am a good sport, win or lose.",
      "I am thoughtful and caring.",
      "I am a great communicator.",
      "I am adventurous and love to explore.",
      "I am honest and trustworthy.",
      "I am a good decision maker.",
      "I am always learning and growing.",
      "I am respectful of nature.",
      "I am happy and joyful.",
      "I am good at sharing and taking turns.",
      "I am considerate of others' feelings.",
      "I am proud of my achievements.",
      "I am brave enough to try new things.",
      "I am always trying my best.",
      "I am an important part of my community.",
      "I am thankful for my family and friends.",
      "I am full of great ideas.",
      "I am a good reader and learner.",
      "I am kind to animals.",
      "I am strong and healthy.",
      "I am excited to see what each day brings.",
      "I am smart and capable.",
      "I am a good helper at home and school.",
      "I am patient with myself and others.",
      "I am grateful for the little things.",
      "I am a good friend to myself.",
      "I am always improving.",
      "I am gentle and caring.",
      "I am good at following instructions.",
      "I am a good role model.",
      "I am friendly and approachable.",
      "I am happy with who I am.",
      "I am a good listener and learner.",
      "I am confident in my uniqueness.",
      "I am thoughtful and empathetic.",
      "I am a good student.",
      "I am loving and lovable.",
      "I am always willing to help.",
      "I am a good thinker.",
      "I am respectful of others' opinions.",
      "I am a quick learner.",
      "I am grateful for my talents.",
      "I am excited to learn new things.",
      "I am proud of my heritage and culture.",
      "I am an important friend.",
      "I am full of potential.",
      "I am caring and thoughtful.",
      "I am always kind, even when it's hard.",
      "I am grateful for the people in my life.",
      "I am a good example for others.",
      "I am full of hope and positivity.",
      "I am thoughtful about my actions.",
      "I am a good planner and organizer.",
      "I am gentle with myself when I make mistakes.",
      "I am respectful of my body.",
      "I am always trying to be my best self.",
      "I am mindful of others' feelings.",
      "I am grateful for my health.",
      "I am always ready to learn and grow.",
      "I am a good team player.",
      "I am kind to my environment.",
      "I am always ready to share.",
      "I am respectful of my own and others' belongings.",
      "I am brave enough to stand up for what's right.",
      "I am full of enthusiasm.",
      "I am a good friend who listens.",
      "I am respectful of different perspectives.",
      "I am grateful for each new day.",
      "I am always curious and asking questions.",
      "I am a good leader and follower.",
      "I am always smiling and spreading joy.",
      "I am patient with my learning process.",
      "I am a good artist and creator.",
      "I am proud to be me!"
    ]
  }
  
  export default affirmations;