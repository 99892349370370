import React, { useState } from 'react';
import SelectDropdown from './SelectDropdown'; // Your existing dropdown component

const problemOptions = {
    first: [
        { value: 'addition', label: 'Addition' },
        { value: 'clock', label: 'Clock' },
    ],
    second: [
        { value: 'addition', label: 'Addition' },
        { value: 'subtraction', label: 'Subtraction' },
        { value: 'clock', label: 'Clock' },
    ],
    third: [
        { value: 'addition', label: 'Addition' },
        { value: 'subtraction', label: 'Subtraction' },
        { value: 'multiplication', label: 'Multiplication' },
        { value: 'division', label: 'Division' },
        { value: 'clock', label: 'Clock' },
    ],
    fourth: [
        { value: 'addition', label: 'Addition' },
        { value: 'subtraction', label: 'Subtraction' },
        { value: 'multiplication', label: 'Multiplication' },
        { value: 'division', label: 'Division' },
        { value: 'clock', label: 'Clock' },
    ],
};

const GradeSelector = ({
    onGradeChange,
    onProblemTypeChange,
    problemTypeOptions = problemOptions,
    defaultGrade = 'first',
    defaultProblemType = 'addition',
}) => {
    const [selectedGrade, setSelectedGrade] = useState(defaultGrade);
    const [selectedProblemType, setSelectedProblemType] = useState(defaultProblemType);

    const handleGradeChange = (grade) => {
        setSelectedGrade(grade);
        onGradeChange && onGradeChange(grade); // Optional callback

        // Automatically select the first problem type for the new grade
        const problemTypes = problemTypeOptions?.[grade] || [];
        const firstProblemType = problemTypes.length > 0 ? problemTypes[0]?.value : '';
        setSelectedProblemType(firstProblemType);

        onProblemTypeChange && onProblemTypeChange(firstProblemType);
    };

    return (
        <div className="w-full flex flex-col items-center mb-4">
            {/* Grade Dropdown */}
            <label id="grade-label" className="text-lg font-semibold mb-2">Grade:</label>
            <SelectDropdown
                value={selectedGrade}
                id="grade-dropdown"
                options={[
                    { value: 'first', label: 'First Grade' },
                    { value: 'second', label: 'Second Grade' },
                    { value: 'third', label: 'Third Grade' },
                    { value: 'fourth', label: 'Fourth Grade' },
                ]}
                onChange={handleGradeChange}
                ariaLabel="Grade"
            />

            {/* Problem Type Dropdown */}
            <label id="problem-type-label" className="text-lg font-semibold mb-2">Problem Type:</label>
            <SelectDropdown
                value={selectedProblemType}
                id="problem-type-dropdown"
                options={problemTypeOptions[selectedGrade] || []}
                onChange={(problemType) => {
                    setSelectedProblemType(problemType);
                    onProblemTypeChange && onProblemTypeChange(problemType); // Optional callback
                }}
                ariaLabel="Problem Type"
            />
        </div>
    );
};

export default GradeSelector;