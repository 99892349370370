export const debugLog = (...args) => {
    if (process.env.REACT_APP_DEBUG === 'true') {
        console.log(...args);
    }
};

export const debugWarn = (...args) => {
    if (process.env.REACT_APP_DEBUG === 'true') {
        console.warn(...args);
    }
};

export const debugError = (...args) => {
    if (process.env.REACT_APP_DEBUG === 'true') {
        console.error(...args);
    }
};