import React, { useState, useEffect, useRef } from 'react';

const SelectDropdown = ({ id = 'default-id', options, value, onChange, className, ariaLabel }) => {
    const [isOpen, setIsOpen] = useState(false);
    const uniqueId = id || 'default-id';
    const dropdownRef = useRef(null);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <div
            ref={dropdownRef}
            className={`relative w-full ${className}`}
            role="combobox"
            id={uniqueId}
            aria-expanded={isOpen}
            aria-haspopup="listbox"
            aria-label={ariaLabel}
            aria-controls={isOpen ? `${uniqueId}-listbox` : undefined}
            onClick={() => setIsOpen((prev) => !prev)}
        >
            {/* Current selection */}
            <div
                className="p-2 mb-2 border-2 rounded cursor-pointer bg-white text-gray-700 border-gray-300"
                tabIndex={0}
                aria-labelledby={`${uniqueId}-selected`}
            >
                <span id={`${uniqueId}-selected`}>
                    {options.find((option) => option.value === value)?.label || 'Select an option'}
                </span>
            </div>
            {/* Dropdown options */}
            {isOpen && (
                <div
                    className="absolute left-0 w-full mt-1 bg-white border border-gray-300 rounded shadow-lg z-10"
                    role="listbox"
                    aria-labelledby={`${uniqueId}-selected`}
                >
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={`p-2 mb-2 cursor-pointer hover:bg-indigo-500 hover:text-white ${option.value === value ? 'bg-indigo-100' : ''
                                }`}
                            role="option"
                            aria-selected={option.value === value}
                            tabIndex={-1}
                            onClick={() => onChange(option.value)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SelectDropdown;