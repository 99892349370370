// BeadFrame.jsx
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import '../BeadFrame.css';

function BeadFrame({
    problem,
    initialValue = 0,
    onSubmitValue,
    resetBeadFrame,
    onResetComplete,
    numberOfBeads = 10,
    lineWidths: propLineWidths,
    beadWidths: propBeadWidths,
    beadSpacings: propBeadSpacings,
}) {
    const rowColors = [
        {
            highlight: '#bbf7d0', // Light green
            base: '#22c55e',      // Base green
            shadow: '#15803d',    // Dark green
        },
        {
            highlight: '#bfdbfe', // Light blue
            base: '#3b82f6',      // Base blue
            shadow: '#1e3a8a',    // Dark blue
        },
        {
            highlight: '#fecaca', // Light red
            base: '#ef4444',      // Base red
            shadow: '#7f1d1d',    // Dark red
        },
        {
            highlight: '#bbf7d0', // Light green
            base: '#22c55e',      // Base green
            shadow: '#15803d',    // Dark green
        },
    ];

    const placeValues = [1, 10, 100, 1000];

    const [beadPositions, setBeadPositions] = useState([]);
    const [initialPositions, setInitialPositions] = useState([]);
    const beadFrameRefs = useRef(Array.from({ length: 4 }, () => null));
    const [lineWidths, setLineWidths] = useState(propLineWidths || []);
    const [beadWidths, setBeadWidths] = useState(propBeadWidths || []);
    const [beadSpacings, setBeadSpacings] = useState(propBeadSpacings || []);
    const [isAnimating, setIsAnimating] = useState(false);
    const animationDuration = 250;
    const TOLERANCE_FACTOR = 0.10; // 10% of the bead width

    const beadFrameRef = useRef(null);
    useEffect(() => {
        const preventScroll = (e) => {
            // Allow touch events on chevrons to propagate
            if (e.target.closest('.chevron')) return;

            // Otherwise, prevent default scrolling behavior
            e.preventDefault();
        };

        const beadFrame = beadFrameRef.current;
        if (beadFrame) {
            beadFrame.addEventListener('touchstart', preventScroll, { passive: false });
            beadFrame.addEventListener('touchmove', preventScroll, { passive: false });
        }

        return () => {
            if (beadFrame) {
                beadFrame.removeEventListener('touchstart', preventScroll);
                beadFrame.removeEventListener('touchmove', preventScroll);
            }
        };
    }, []);

    const animatePositions = (startPositions, targetPositions, duration, updateCallback, onComplete) => {
        if (!Array.isArray(startPositions) || !Array.isArray(targetPositions)) {
            console.error('animatePositions requires array inputs.');
            return;
        }

        if (startPositions.length !== targetPositions.length) {
            console.error('Mismatched row counts in startPositions and targetPositions.');
            return;
        }

        startPositions.forEach((row, index) => {
            if (row.length !== targetPositions[index].length) {
                console.error(`Mismatched bead counts in row ${index}.`);
                return;
            }
        });

        setIsAnimating(true); // Start animation

        const startTime = performance.now();

        const animate = (currentTime) => {
            const elapsed = currentTime - startTime;
            const progress = Math.min(elapsed / duration, 1);
            const easedProgress = progress < 0.5
                ? 2 * progress * progress
                : -1 + (4 - 2 * progress) * progress;

            const newPositions = startPositions.map((row, rowIndex) =>
                row.map((start, beadIndex) => {
                    const target = targetPositions[rowIndex][beadIndex];
                    return start + (target - start) * easedProgress;
                })
            );

            updateCallback(newPositions);

            if (progress < 1) {
                requestAnimationFrame(animate);
            } else {
                setIsAnimating(false); // End animation
                if (onComplete) {
                    onComplete();
                }
            }
        };

        requestAnimationFrame(animate);
    };

    // Update lineWidth based on container size
    useEffect(() => {
        const updateDimensions = () => {
            // Skip dynamic updates if propLineWidths, propBeadWidths, or propBeadSpacings are provided
            if (propLineWidths && propBeadWidths && propBeadSpacings) {
                setLineWidths(propLineWidths);
                setBeadWidths(propBeadWidths);
                setBeadSpacings(propBeadSpacings);
                return; // Exit early as the props are already defined
            }

            const newLineWidths = [];
            const newBeadWidths = [];
            const newBeadSpacings = [];

            beadFrameRefs.current.forEach((ref, index) => {
                if (!ref) {
                    console.warn(`beadFrameRefs.current[${index}] is null`);
                    return;
                }
                if (ref) {
                    const frameWidth = ref.offsetWidth;
                    newLineWidths[index] = frameWidth;

                    const calculatedBeadWidth = frameWidth * 0.05; // Adjust as needed
                    const calculatedBeadSpacing = frameWidth * 0.005; // Adjust as needed

                    newBeadWidths[index] = calculatedBeadWidth;
                    newBeadSpacings[index] = calculatedBeadSpacing;
                } else {
                    console.warn(`beadFrameRefs.current[${index}] is null`);
                }
            });

            setLineWidths(newLineWidths);
            setBeadWidths(newBeadWidths);
            setBeadSpacings(newBeadSpacings);
        };

        updateDimensions();

        if (!propLineWidths || !propBeadWidths || !propBeadSpacings) {
            // Only add resize listener if dimensions are dynamically calculated
            window.addEventListener('resize', updateDimensions);
            return () => {
                window.removeEventListener('resize', updateDimensions);
            };
        }
    }, [propLineWidths, propBeadWidths, propBeadSpacings]);

    // Calculate initial positions based on initialValue and dimensions
    useEffect(() => {
        if (lineWidths.length === 0) return;

        const maxRows = 4;
        const problemDigits = String(initialValue)
            .padStart(maxRows, '0')
            .split('')
            .reverse()
            .map(Number);

        const calculatedInitialPositions = Array.from({ length: maxRows }, (_, rowIndex) => {
            const positions = [];
            const count = problemDigits[rowIndex]; // Number of beads to activate
            const lineWidth = lineWidths[rowIndex] || 400;
            const beadWidth = beadWidths[rowIndex] || 20;
            const beadSpacing = beadSpacings[rowIndex] || 2;

            for (let i = 0; i < numberOfBeads; i++) {
                const activatedPosition =
                    lineWidth - (numberOfBeads - i) * (beadWidth + beadSpacing);
                const unactivatedPosition = i * (beadWidth + beadSpacing);

                positions[i] = i >= numberOfBeads - count ? activatedPosition : unactivatedPosition;
            }

            return positions;
        });

        setInitialPositions(calculatedInitialPositions);
        setBeadPositions(calculatedInitialPositions);
    }, [initialValue, lineWidths, beadWidths, beadSpacings, numberOfBeads]);

    // Recalculate bead positions on dimension changes
    useEffect(() => {
        if (lineWidths.length === 0 || beadWidths.length === 0) return;

        setBeadPositions((prevPositions) =>
            prevPositions.map((row, rowIndex) => {
                const lineWidth = lineWidths[rowIndex] || 400;
                const beadWidth = beadWidths[rowIndex] || 20;
                const beadSpacing = beadSpacings[rowIndex] || 2;

                if (lineWidth === undefined || beadWidth === undefined || beadSpacing === undefined) {
                    console.warn(`Dimensions not set for row ${rowIndex}`);
                    return row; // Return the row as-is if dimensions are missing
                }

                return row.map((_, beadIndex) => {
                    const wasActivated =
                        prevPositions[rowIndex][beadIndex] >=
                        lineWidth -
                        (numberOfBeads - beadIndex) * (beadWidth + beadSpacing) -
                        0.5 * beadWidth;

                    const activatedPosition =
                        lineWidth - (numberOfBeads - beadIndex) * (beadWidth + beadSpacing);
                    const unactivatedPosition = beadIndex * (beadWidth + beadSpacing);

                    return wasActivated ? activatedPosition : unactivatedPosition;
                });
            })
        );
    }, [lineWidths, beadWidths, beadSpacings, numberOfBeads]);

    const applyBoundaryConstraints = useCallback(
        (newPositions, rowIndex, beadIndex, delta) => {
            const lineWidth = lineWidths[rowIndex] || 400;
            const beadWidth = beadWidths[rowIndex] || 20;
            const beadSpacing = beadSpacings[rowIndex] || 2;

            if (lineWidth === undefined || beadWidth === undefined || beadSpacing === undefined) {
                console.error(`Missing dimensions for row ${rowIndex}.`);
                return newPositions[rowIndex][beadIndex];
            }

            const activatedPosition =
                lineWidth - (numberOfBeads - beadIndex) * (beadWidth + beadSpacing);
            const unactivatedPosition = beadIndex * (beadWidth + beadSpacing);

            const newPosition = newPositions[rowIndex][beadIndex] + delta;

            return Math.max(
                unactivatedPosition,
                Math.min(newPosition, activatedPosition)
            );
        },
        [lineWidths, beadWidths, beadSpacings, numberOfBeads]
    );

    const handleDrag = useCallback(
        (rowIndex, beadIndex, deltaX) => {
            if (!beadWidths[rowIndex] || !beadSpacings[rowIndex]) {
                console.error(`Invalid dimensions for drag on row ${rowIndex}`);
                return;
            }

            const beadWidth = beadWidths[rowIndex] || 20;
            const beadSpacing = beadSpacings[rowIndex] || 2;

            setBeadPositions((prevPositions) => {
                const newPositions = prevPositions.map((row) => [...row]);
                const row = newPositions[rowIndex];

                // Move the dragged bead
                row[beadIndex] = applyBoundaryConstraints(
                    newPositions,
                    rowIndex,
                    beadIndex,
                    deltaX
                );

                // Adjust beads to the right
                for (let i = beadIndex + 1; i < numberOfBeads; i++) {
                    row[i] = Math.max(
                        row[i],
                        row[i - 1] + beadWidth + beadSpacing
                    );
                }

                // Adjust beads to the left
                for (let i = beadIndex - 1; i >= 0; i--) {
                    row[i] = Math.min(
                        row[i],
                        row[i + 1] - beadWidth - beadSpacing
                    );
                }

                return newPositions;
            });
        },
        [applyBoundaryConstraints, beadWidths, beadSpacings, numberOfBeads]
    );

    const handleMouseDown = (rowIndex, beadIndex, e) => {
        e.preventDefault();
        handleStart(rowIndex, beadIndex, e.clientX);
    };

    const handleTouchStart = (rowIndex, beadIndex, e) => {
        handleStart(rowIndex, beadIndex, e.touches[0].clientX);
    };

    // Mouse down and touch handling functions
    const handleStart = (rowIndex, beadIndex, startX) => {

        let lastX = startX;
        let animationFrameId = null;

        const handleMove = (e) => {
            const clientX = e.clientX || (e.touches && e.touches[0].clientX);
            const deltaX = clientX - lastX;
            // Use requestAnimationFrame to batch updates
            if (!animationFrameId) {
                animationFrameId = requestAnimationFrame(() => {
                    handleDrag(rowIndex, beadIndex, deltaX);
                    lastX = clientX; // Update lastX only after the drag is processed
                    animationFrameId = null; // Reset animation frame
                });
            }

        };

        const handleEnd = () => {
            // Clean up after dragging ends
            document.removeEventListener('mousemove', handleMove);
            document.removeEventListener('mouseup', handleEnd);
            document.removeEventListener('touchmove', handleMove);
            document.removeEventListener('touchend', handleEnd);
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId); // Clean up pending frames
            }
        };

        document.addEventListener('mousemove', handleMove);
        document.addEventListener('mouseup', handleEnd);
        document.addEventListener('touchmove', handleMove);
        document.addEventListener('touchend', handleEnd);
    };

    // Calculate activated beads
    const calculateActivatedBeads = (positions) => {
        return positions.map((row, rowIndex) => {
            const lineWidth = lineWidths[rowIndex] || 400;
            const beadWidth = beadWidths[rowIndex] || 20;
            const beadSpacing = beadSpacings[rowIndex] || 2;

            return row.filter(
                (position, i) =>
                    position >=
                    lineWidth -
                    (numberOfBeads - i) * (beadWidth + beadSpacing) -
                    0.5 * beadWidth
            ).length;
        });
    };

    const activatedBeads = calculateActivatedBeads(beadPositions);
    const totalValue = activatedBeads.reduce(
        (sum, count, index) => sum + count * placeValues[index],
        0
    );

    // Call onSubmitValue when submit is requested by QandA
    useEffect(() => {
        if (onSubmitValue) {
            onSubmitValue(totalValue);  // Provide function to QandA for later use
        }
    }, [onSubmitValue, totalValue]);

    // Handle reset animation
    useEffect(() => {
        if (resetBeadFrame) {
            const duration = animationDuration; // Animation duration in ms

            animatePositions(
                beadPositions,
                initialPositions,
                duration,
                setBeadPositions,
                onResetComplete
            );
        }
    }, [resetBeadFrame, beadPositions, initialPositions, onResetComplete]);

    const moveBead = (rowIndex, direction) => {
        if (isAnimating) return; // Ignore chevron press during animation

        setBeadPositions((prevPositions) => {
            const startRow = [...prevPositions[rowIndex]];
            const targetRow = [...startRow];

            const lineWidth = lineWidths[rowIndex] || 400;
            const beadWidth = beadWidths[rowIndex] || 20;
            const beadSpacing = beadSpacings[rowIndex] || 2;

            if (direction === 'right') {
                const tolerance = beadWidth * 0.5; // Allowable tolerance for bead positions

                // Check if there are any activated beads
                const hasActivatedBeads = startRow.some((pos, i) => {
                    const activatedPosition =
                        lineWidth - (numberOfBeads - i) * (beadWidth + beadSpacing);
                    return pos >= activatedPosition - tolerance;
                });

                if (hasActivatedBeads) {
                    // Find the rightmost unactivated bead
                    const firstUnactivatedIndex = startRow
                        .slice()
                        .reverse() // Reverse the array to search from right to left
                        .findIndex((pos, i) => {
                            const actualIndex = numberOfBeads - 1 - i; // Map reversed index back to original
                            const activatedPosition =
                                lineWidth - (numberOfBeads - actualIndex) * (beadWidth + beadSpacing);
                            return pos < activatedPosition - tolerance; // Not yet activated
                        });

                    const correctedIndex = firstUnactivatedIndex !== -1
                        ? numberOfBeads - 1 - firstUnactivatedIndex
                        : -1;

                    if (correctedIndex !== -1) {
                        // Move only this bead and beads to its right
                        for (let i = correctedIndex; i < numberOfBeads; i++) {
                            targetRow[i] =
                                lineWidth - (numberOfBeads - i) * (beadWidth + beadSpacing);
                        }
                    }
                } else {
                    // No activated beads: Use the original fallback behavior
                    for (let i = numberOfBeads - 1; i >= 0; i--) {
                        const activatedPosition =
                            lineWidth - (numberOfBeads - i) * (beadWidth + beadSpacing);
                        if (startRow[i] !== activatedPosition) {
                            targetRow[i] = activatedPosition;
                            break;
                        }
                    }
                }
            } else if (direction === 'left') {
                // Find the leftmost activated bead
                let activatedIndex = startRow.findIndex(
                    (pos, i) =>
                        pos >=
                        lineWidth -
                        (numberOfBeads - i) * (beadWidth + beadSpacing) -
                        0.5 * beadWidth
                );

                if (activatedIndex !== -1) {
                    // Move all beads left of and including the activated bead to their unactivated positions
                    for (let i = activatedIndex; i >= 0; i--) {
                        targetRow[i] = i * (beadWidth + beadSpacing);
                    }
                } else {
                    // Fallback: move the leftmost unactivated bead to its starting position
                    for (let i = 0; i < numberOfBeads; i++) {
                        const unactivatedPosition = i * (beadWidth + beadSpacing);
                        if (startRow[i] !== unactivatedPosition) {
                            targetRow[i] = unactivatedPosition;
                            break;
                        }
                    }
                }
            }

            const targetPositions = prevPositions.map((row, i) =>
                i === rowIndex ? targetRow : [...row]
            );

            animatePositions(prevPositions, targetPositions, animationDuration, setBeadPositions);
            return prevPositions; // Return current state; animation updates asynchronously
        });
    };

    // Check if the icons should be disabled
    const isMoveDisabled = useCallback(
        (rowIndex, direction) => {
            const row = beadPositions[rowIndex];
            if (!row) {
                console.error(`Row at index ${rowIndex} is undefined.`);
                return true; // Disable movement if the row doesn't exist
            }

            const lineWidth = lineWidths[rowIndex] || 400;
            const beadWidth = beadWidths[rowIndex] || 20;
            const beadSpacing = beadSpacings[rowIndex] || 2;
            const tolerance = TOLERANCE_FACTOR * beadWidth;

            if (direction === 'right') {
                return !row.some(
                    (pos, i) => Math.abs(pos - (lineWidth - (numberOfBeads - i) * (beadWidth + beadSpacing))) > tolerance
                );
            } else if (direction === 'left') {
                return !row.some(
                    (pos, i) => Math.abs(pos - i * (beadWidth + beadSpacing)) > tolerance
                );
            }
            return true;
        },
        [lineWidths, beadPositions, beadWidths, beadSpacings, numberOfBeads]
    );

    const handleChevronClick = (rowIndex, direction) => {
        if (!isAnimating) {
            moveBead(rowIndex, direction);
        }
    };

    return (
        <div ref={beadFrameRef} className="bead-frame-container">
            <span className='problem-display'>
                {problem.num1} {problem.type} {problem.num2}
            </span>
            {(beadPositions.length > 0 ? beadPositions : Array.from({ length: 4 }, () => [])).map(
                (positions, rowIndex) => (
                    <div key={rowIndex} className="bead-row" data-testid={`bead-row-${rowIndex}`}>
                        <div className="chevron chevron-left-container"
                            role="button" // Make it a button
                            aria-label="chevron left" // Add an accessible label
                        >
                            <FontAwesomeIcon
                                icon={faChevronLeft}
                                className={`bead-row-icon ${!isMoveDisabled(rowIndex, 'left') ? 'active' : 'disabled'}`}
                                onClick={() => !isMoveDisabled(rowIndex, 'left') && handleChevronClick(rowIndex, 'left')}
                                data-testid={`chevron-left-${rowIndex}`}
                            />
                        </div>
                        <div
                            className="bead-frame"
                            ref={(el) => (beadFrameRefs.current[rowIndex] = el)}
                        >
                            <div className="bead-frame-line" />
                            {/*}
                            <div className="bead-frame-label">{placeValues[rowIndex]}s</div>
                            {*/}
                            {positions.map((position, beadIndex) => {
                                const beadWidth = beadWidths[rowIndex] || 20; // Default value if not yet set
                                return (
                                    <div
                                        key={beadIndex}
                                        className="bead"
                                        data-testid={`bead-${rowIndex}-${beadIndex}`}
                                        onMouseDown={(e) => handleMouseDown(rowIndex, beadIndex, e)}
                                        onTouchStart={(e) => handleTouchStart(rowIndex, beadIndex, e)}
                                        style={{
                                            transform: `translateX(${position}px)`,
                                            width: beadWidth,
                                            height: beadWidth,
                                            top: `calc(50% - ${beadWidth / 2}px)`,
                                            background: `radial-gradient(
                  circle,
                  ${rowColors[rowIndex].highlight} 20%,
                  ${rowColors[rowIndex].base} 60%,
                  ${rowColors[rowIndex].shadow} 100%
                )`,
                                            boxShadow:
                                                'inset 0 2px 4px rgba(0, 0, 0, 0.6), 0 4px 8px rgba(0, 0, 0, 0.4)',
                                        }}
                                    />
                                );
                            })}
                        </div>
                        <div className="chevron chevron-right-container"
                            role="button" // Make it a button
                            aria-label="chevron right" // Add an accessible label
                        >
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className={`bead-row-icon ${isMoveDisabled(rowIndex, 'right') ? 'disabled' : 'active'}`}
                                onClick={() => !isMoveDisabled(rowIndex, 'right') && handleChevronClick(rowIndex, 'right')}
                                data-testid={`chevron-right-${rowIndex}`}
                            />
                        </div>
                    </div>
                ))}

            <div className="bead-frame-tally">
                {/*}
                {activatedBeads.map((count, index) => (
                    <div key={index} className="tally-item">
                        {placeValues[index]}s: {count * placeValues[index]}
                    </div>
                ))}
                {*/}
                <div
                    className="tally-total"
                    data-testid="tally-total"
                >Total Value: {totalValue}</div>
            </div>
        </div>
    );
}

export default BeadFrame;