import React, { useEffect } from 'react';

const Snackbar = ({ message, type, isVisible, onClose }) => {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, 5000); // 5 seconds duration

      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  return (
    isVisible && (
      <div className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 
          ${type === 'success' ? 'bg-green-500' : type === 'error' ? 'bg-red-500' : 'bg-yellow-500'} 
          text-white px-6 py-3 rounded-lg shadow-lg transition-opacity ease-in-out duration-300 z-50`}>
        {message}
      </div>
    )
  );
};

export default Snackbar;