import { logEvent } from '../analytics';
import { debugLog } from '../utils';

const QandASummary = ({
    problems,
    elapsedTime,
    state = { questionStates: [], progress: { skipped: 0 }, session: { mode: 'endless' } },
    modeConfig,
}) => {
    if (!problems || problems.length === 0) {
        debugLog('DEBUG: Problems are empty, rendering fallback message');
        return <div>No problems were attempted during this session.</div>;
    }

    modeConfig = modeConfig || {};

    const totalProblems = problems.length;

    // Calculate correct, incorrect, and skipped counts dynamically
    const correctCount = state.questionStates.filter(q => q.correct === true).length;;
    const incorrectCount = state.questionStates.filter(q => q.correct === false).length;
    const skippedCount = state.progress.skipped;

    let message = "";
    if (state.session.outcome === "timerExpired") {
        message = `Oops! ⏰ Time’s Up! You solved ${correctCount} problems correctly! Keep it up! 🎉`;
    } else if (state.session.outcome === "completed") {
        message = `Awesome job! 🎉 You answered all ${totalProblems} questions with ${correctCount} correct!`;
    } else if (state.session.outcome === "endless") {
        message = `Great work! 🎈 You answered ${correctCount} correctly and skipped ${skippedCount}.`;
    }
    logEvent('Interactive Mode', 'Problems Completed', 'Correct', correctCount);
    logEvent('Interactive Mode', 'Problems Completed', 'Incorrect', incorrectCount);
    logEvent('Interactive Mode', 'Problems Completed', 'Skipped', skippedCount);

    debugLog("problems: ", problems);

    return (
        <div className="w-full max-w-4xl mx-auto px-4 py-8">
            <h2 className="text-3xl font-bold text-center mb-8">{message}</h2>

            {modeConfig?.difficulty && (
                <div className="mb-8 p-4 border border-green-500 rounded-lg text-indigo-700 text-center mx-auto w-1/3">
                    <h3 className="text-xl font-semibold">Difficulty</h3>
                    <p className="text-2xl font-bold">
                        {modeConfig?.difficulty === 'easy' ? 'Easy' : modeConfig.difficulty === 'medium' ? 'Medium' : 'Hard'}
                    </p>
                </div>
            )}

            {modeConfig?.grade && state.session.mode != 'bead' && (
                <div className="mb-8 p-4 border border-green-500 rounded-lg text-indigo-700 text-center mx-auto w-1/3">
                    <h3 className="text-xl font-semibold">Grade</h3>
                    <p className="text-2xl font-bold">
                        {modeConfig?.grade === 'first' ? 'First' : modeConfig.grade === 'second' ? 'Second' : modeConfig.grade === 'third' ? 'Third' : 'Fourth'}
                    </p>
                </div>
            )}

            {elapsedTime && (
                <div className="mb-8 p-4 border border-indigo-500 rounded-lg bg-indigo-100 text-indigo-700 text-center mx-auto w-1/3">
                    <h3 className="text-xl font-semibold">Elapsed Time</h3>
                    <p className="text-2xl font-bold">
                        {Math.floor(elapsedTime / 60)}m {elapsedTime % 60}s
                    </p>
                </div>
            )}

            <div className={`grid ${state.session?.mode === 'endless' ? 'grid-cols-3' : 'grid-cols-2'} gap-4 mb-8`}>
                <div className="bg-green-100 border border-green-500 p-4 rounded-lg text-center" data-testid="correct-count">
                    <div className="font-semibold text-green-700">Correct</div>
                    <div className="text-2xl">{correctCount}</div>
                </div>
                <div className="bg-red-100 border border-red-500 p-4 rounded-lg text-center" data-testid="incorrect-count">
                    <div className="font-semibold text-red-700">Incorrect</div>
                    <div className="text-2xl">{incorrectCount}</div>
                </div>
                {state.session.mode === 'endless' && (
                    <div className="bg-yellow-100 border border-yellow-500 p-4 rounded-lg text-center" data-testid="skipped-count">
                        <div className="font-semibold text-yellow-700">Skipped</div>
                        <div className="text-2xl">{skippedCount}</div>
                    </div>
                )}
            </div>

            {/* Display problem list and results */}
            <div className="space-y-4">
                {problems.map((problem, index) => {
                    // Error check to ensure `state.questionStates` exists and has an entry for the current index
                    const correct = state.questionStates?.[index]?.correct;

                    return (
                        <div
                            key={index}
                            data-testid={`problem-result-${index}`}
                            className={`p-4 rounded-lg border ${correct === true ? 'bg-green-100 border border-green-500' :
                                correct === false ? 'bg-red-100 border border-red-500 ' :
                                    'bg-yellow-100 border border-yellow-500 '
                                }`}
                        >
                            <div className="flex justify-between items-center">
                                <div>
                                    {problem.type === 'clock' ? (
                                        <>Problem {index + 1}: {problem.time}</>
                                    ) : (
                                        <>Problem {index + 1}: {problem.num1} {problem.type} {problem.num2} = {problem.answer}</>
                                    )}
                                </div>
                                <div>
                                    Your Answer: {problem.userAnswer !== undefined ? problem.userAnswer : 'N/A'}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            {/*}
            <div className="mt-8 text-center">
                <button
                    onClick={handleBackToHomeSelection}
                    className="px-6 py-3 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors"
                >
                    🎈 Go Back Home 🎈
                </button>
            </div>
            {*/}
        </div>
    );
};

export default QandASummary;