import React from 'react'

const Header = () => {
  return (
    <div className='grid grid-cols-2 gap-4 invisible text-sm print-header'>
        <div></div>
        <div className="name-line py-1 m-1">Name: </div>
    </div>
  )
}

export default Header