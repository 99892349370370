import { default as mathC } from './Constants.js';

// Utility function to check for duplicates
export const isDuplicate = (newProblem, existingProblems) => {
  return existingProblems.some(problem => {
    if (newProblem.type === mathC.CLOCK_TYPE) {
      return problem.time === newProblem.time;
    } else {
      return problem.num1 === newProblem.num1 && problem.num2 === newProblem.num2 && problem.type === newProblem.type;
    }
  });
};

// Utility function to calculate the number of unique problems possible
export const calculateNumberOfUniqueProblems = (digits, operationType, limit) => {
  if (operationType === mathC.MULTIPLICATION_TYPE) {
    // Times table calculation
    return limit + 1;
  }


  let min = Math.pow(10, digits - 1);
  let max = Math.min(Math.pow(10, digits) - 1, limit);

  if (digits === 1) {
    min = 0; // Adjust for 1-digit numbers
    max = Math.min(9, limit); // Ensure max is within 0-9 range
  }

  let uniqueProblemsCount = 0;

  if (operationType === '+' || operationType === '*') {
    uniqueProblemsCount = (max - min + 1) * (max - min + 1);
  } else if (operationType === '-') {
    for (let num1 = min; num1 <= max; num1++) {
      for (let num2 = min; num2 <= num1; num2++) {
        uniqueProblemsCount++;
      }
    }
  }

  return uniqueProblemsCount;
};

// Main problem generation function
export const generateProblems = (problemTypes = {}, numberOfProblems, problemTypesAndNames = {}, limit, factor) => {
  if (!problemTypes || typeof problemTypes !== 'object') return [];
  //console.time('Generate Problems');
  const newProblems = [];
  const types = Object.keys(problemTypes).filter(type => problemTypes[type]);
  let zeroCount = 0;
  let iterationCount = 0;
  const maxIterations = 10000;

  if (types.length < 1) {
    return [];
  }

  // Calculate the total number of unique problems available for the selected types
  let numberOfUniqueProblems = 0;
  types.forEach(type => {
    const newType = problemTypesAndNames[type];
    numberOfUniqueProblems += calculateNumberOfUniqueProblems(newType.digits, newType.type, limit);
  });

  // Generate unique problems or until max iterations are hit
  while (newProblems.length < numberOfProblems && iterationCount < maxIterations) {
    const randomType = types[Math.floor(Math.random() * types.length)];
    const newType = problemTypesAndNames[randomType];
    let newProblem;

    switch (randomType) {
      case 'oneDigitAddition':
      case 'twoDigitAddition':
      case 'threeDigitAddition':
      case 'oneDigitSubtraction':
      case 'twoDigitSubtraction':
      case 'threeDigitSubtraction':
      case 'oneDigitMultiplication':
      case 'twoDigitMultiplication':
      case 'threeDigitMultiplication':
      case 'division':
        newProblem = generateProblem(newType.digits, newType.type, limit);
        break;
      case 'timesTable':
        newProblem = generateTimesTableProblem(factor, limit);
        break;
      case 'clockProblem':
        const time = generateRandomTime();
        newProblem = generateClockProblem(time, newType.type);
        break;
      default:
        break;
    }

    iterationCount++;

    if (iterationCount % 20 === 0) {
      setTimeout(() => { }, 0);
    }

    // Check for duplicates
    if (!isDuplicate(newProblem, newProblems) || newProblems.length >= numberOfUniqueProblems) {

      // Check if the problem involves zero
      const isZeroBased = (newProblem.num1 === 0 || newProblem.num2 === 0);

      // Only add the zero-based problem if it's the first occurrence
      if (isZeroBased) {
        if (zeroCount < 1) {
          zeroCount++; // Increment zeroCount for the first zero-based problem
          newProblems.push(newProblem); // Add to problems
        }
      } else {
        // Add the problem if it's not zero-based
        newProblems.push(newProblem);
      }
    }
  }

  // Allow duplicates if unique problems are exhausted, but still respect the zero limit
  while (newProblems.length < numberOfProblems) {
    const randomType = types[Math.floor(Math.random() * types.length)];
    const newType = problemTypesAndNames[randomType];
    let newProblem = generateProblem(newType.digits, newType.type, limit);

    // Check for zero and respect the zeroCount limit
    const isZeroBased = (newProblem.num1 === 0 || newProblem.num2 === 0);
    if (isZeroBased && zeroCount >= 1) {
      continue; // Skip this problem if zero has already been used
    }

    if (isZeroBased) {
      zeroCount++; // Increment zeroCount if adding a zero-based problem
    }

    newProblems.push(newProblem);
  }

  //console.timeEnd('Generate Problems');
  return newProblems;  // Return the problems
};

// Function to generate an addition problem with or without carryover
export function generateAdditionProblem(digits, difficultyLevel) {
  let hasCarryover = false;

  while (true) {
    let num1Digits = [];
    let num2Digits = [];

    // Determine positions where carryover should occur
    let carryPositions = [];
    if (difficultyLevel === 'hard') {
      // At least one position must cause a carryover
      carryPositions = [getRandomIntInclusive(0, digits - 1)];
    } else if (difficultyLevel === 'medium') {
      // Randomly decide whether each position causes a carryover
      for (let i = 0; i < digits; i++) {
        if (Math.random() < 0.5) {
          carryPositions.push(i);
        }
      }
    } else {
      // Ensure no carryover
      carryPositions = [];
    }

    for (let i = 0; i < digits; i++) {
      let digit1, digit2;

      if (carryPositions.includes(i)) {
        // Force carryover in this position
        digit1 = getRandomIntInclusive(0, 9);
        digit2 = getRandomIntInclusive(10 - digit1, 9);
        hasCarryover = true;
      } else {
        // Ensure no carryover in this position
        digit1 = getRandomIntInclusive(0, 9);
        digit2 = getRandomIntInclusive(0, 9 - digit1);
      }

      num1Digits.push(digit1);
      num2Digits.push(digit2);
    }

    // Ensure the first digits are not both zero to maintain the number of digits
    if (num1Digits[0] === 0 && num2Digits[0] === 0) {
      continue; // Regenerate if both leading digits are zero
    }

    const num1Str = num1Digits.join('').replace(/^0+(?=\d)/, '');
    const num2Str = num2Digits.join('').replace(/^0+(?=\d)/, '');    

    const num1Int = parseInt(num1Str, 10);
    const num2Int = parseInt(num2Str, 10);

    // Ensure num1 and num2 have the correct number of digits
    if (num1Str.length !== digits || num2Str.length !== digits) {
      continue; // Regenerate if the number of digits is incorrect
    }

    const answer = num1Int + num2Int;

    return { type: '+', num1: num1Str, num2: num2Str, answer, hasCarryover };
  }
}

// Function to generate a subtraction problem with or without borrowing
export function generateSubtractionProblem(digits, difficultyLevel) {
  // Edge Case 1: Adjust digits for 'hard' difficulty if digits === 1
  if (digits === 1 && difficultyLevel === 'hard') {
    digits = 2; // Increase digits to 2
  }

  let num1Digits = [];
  let num2Digits = [];
  let hasBorrow = false;

  // Build num1 digit by digit, allowing leading zeros
  for (let i = 0; i < digits; i++) {
    const digit1 = getRandomIntInclusive(0, 9);
    num1Digits.push(digit1);
  }

  // Ensure num1 is not a single-digit number
  if (num1Digits.every((digit) => digit === 0)) {
    // Regenerate num1 if all digits are zero
    return generateSubtractionProblem(digits, difficultyLevel);
  }

  // Build num2 digit by digit
  for (let i = 0; i < digits; i++) {
    const digit1 = num1Digits[i];
    let digit2;

    if (i === 0) {
      // Most Significant Digit (MSD): digit2 <= digit1
      if (digit1 === 0) {
        // If MSD of num1 is 0, set digit2 to 0
        digit2 = 0;
      } else {
        digit2 = getRandomIntInclusive(0, digit1);
      }
    } else {
      if (difficultyLevel === 'hard') {
        // Decide whether to force borrowing at this position
        if (!hasBorrow && digit1 < 9) {
          // Force borrowing
          digit2 = getRandomIntInclusive(digit1 + 1, 9);
          hasBorrow = true;
        } else {
          // No borrowing
          digit2 = getRandomIntInclusive(0, digit1);
        }
      } else if (difficultyLevel === 'medium') {
        // 50% chance to require borrowing
        if (Math.random() < 0.5 && digit1 < 9) {
          digit2 = getRandomIntInclusive(digit1 + 1, 9);
          hasBorrow = true;
        } else {
          digit2 = getRandomIntInclusive(0, digit1);
        }
      } else {
        // 'Easy' difficulty: Ensure no borrowing
        digit2 = getRandomIntInclusive(0, digit1);
      }
    }

    num2Digits.push(digit2);
  }

  // Ensure num1 and num2 are multi-digit numbers
  const num1Str = num1Digits.join('').replace(/^0+(?=\d)/, ''); // Remove leading zeros only if there are other digits
  const num2Str = num2Digits.join('').replace(/^0+(?=\d)/, ''); // Same for num2

  // Prevent num1 and num2 from being single-digit numbers due to leading zeros
  if (parseInt(num1Str, 10) < 10 ** (digits - 1) || parseInt(num2Str, 10) < 10 ** (digits - 1)) {
    return generateSubtractionProblem(digits, difficultyLevel);
  }

  const num1Int = parseInt(num1Str, 10);
  const num2Int = parseInt(num2Str, 10);

  // Edge Case 4: Ensure num1 >= num2
  if (num1Int < num2Int) {
    // Regenerate the problem if num1 < num2
    return generateSubtractionProblem(digits, difficultyLevel);
  }

  // Edge Case 3: Handle digit1 = 9 when forcing borrowing
  if (difficultyLevel === 'hard' && !hasBorrow) {
    for (let i = 1; i < digits; i++) {
      const digit1 = num1Digits[i];
      if (digit1 < 9) {
        num2Digits[i] = getRandomIntInclusive(digit1 + 1, 9);
        hasBorrow = true;
        break;
      }
    }
    if (!hasBorrow) {
      // Unable to force borrowing; regenerate problem
      return generateSubtractionProblem(digits, difficultyLevel);
    }
  }

  const answer = num1Int - num2Int;

  // Return the problem with leading zeros preserved
  return {
    type: '-',
    num1: num1Str,
    num2: num2Str,
    answer,
    hasBorrowing: hasBorrow,
  };
}

// Main problem generation function with difficulty
export const generateProblemsWithDifficulty = (
  problemTypes = {},
  numberOfProblems,
  problemTypesAndNames = {},
  limit,
  difficultyLevel = 'medium'
) => {
  if (!problemTypes || typeof problemTypes !== 'object') return [];

  const newProblems = [];
  const types = Object.keys(problemTypes).filter((type) => problemTypes[type]);
  let zeroCount = 0;
  const maxIterations = 10000;

  if (types.length < 1) {
    return [];
  }

  // Set to keep track of generated problems to ensure uniqueness
  const problemSet = new Set();

  // Generate unique problems
  while (newProblems.length < numberOfProblems && problemSet.size < maxIterations) {
    const randomType = types[Math.floor(Math.random() * types.length)];
    const newType = problemTypesAndNames[randomType];
    let newProblem;

    switch (randomType) {
      case 'oneDigitAddition':
      case 'twoDigitAddition':
      case 'threeDigitAddition':
        newProblem = generateAdditionProblem(newType.digits, difficultyLevel);
        break;
      case 'oneDigitSubtraction':
      case 'twoDigitSubtraction':
      case 'threeDigitSubtraction':
        newProblem = generateSubtractionProblem(newType.digits, difficultyLevel);
        break;
      // Handle other problem types as in your existing code
      default:
        continue;
    }

    // Create a unique key for the problem
    const problemKey = `${newProblem.type}-${newProblem.num1}-${newProblem.num2}`;

    if (!problemSet.has(problemKey)) {
      problemSet.add(problemKey);

      // Check if the problem involves zero
      const isZeroBased = newProblem.num1 === 0 || newProblem.num2 === 0;

      // Only add the zero-based problem if it's the first occurrence
      if (isZeroBased) {
        if (zeroCount < 1) {
          zeroCount++; // Increment zeroCount for the first zero-based problem
          newProblems.push(newProblem); // Add to problems
        }
      } else {
        // Add the problem if it's not zero-based
        newProblems.push(newProblem);
      }
    }
  }

  // Allow duplicates if unique problems are exhausted, still respecting zero limit
  while (newProblems.length < numberOfProblems) {
    const randomType = types[Math.floor(Math.random() * types.length)];
    const newType = problemTypesAndNames[randomType];
    let newProblem;

    switch (randomType) {
      case 'oneDigitAddition':
      case 'twoDigitAddition':
      case 'threeDigitAddition':
        newProblem = generateAdditionProblem(newType.digits, difficultyLevel);
        break;
      case 'oneDigitSubtraction':
      case 'twoDigitSubtraction':
      case 'threeDigitSubtraction':
        newProblem = generateSubtractionProblem(newType.digits, difficultyLevel);
        break;
      // Handle other problem types as in your existing code
      default:
        continue;
    }

    // Check if the problem involves zero
    const isZeroBased = newProblem.num1 === 0 || newProblem.num2 === 0;

    // Only add the zero-based problem if it's the first occurrence
    if (isZeroBased) {
      if (zeroCount < 1) {
        zeroCount++; // Increment zeroCount for the first zero-based problem
        newProblems.push(newProblem); // Add to problems
      } else {
        continue; // Skip if zero has already been used
      }
    } else {
      newProblems.push(newProblem);
    }
  }

  return newProblems;
};

// Problem generation helpers
export const generateProblem = (digits, type, limit) => {
  let num1;
  let num2;
  let answer;

  if (type === mathC.DIVISION_TYPE) {
    const divisor = Math.floor(Math.random() * 12) + 1;
    const quotient = Math.floor(Math.random() * 13);
    num1 = divisor * quotient;
    num2 = divisor;
    answer = quotient; // Integer result for division
  } else {
    switch (digits) {
      case 1:
        num1 = Math.floor(Math.random() * 10);
        break;
      case 2:
        num1 = Math.floor(Math.random() * 90) + 10;
        break;
      case 3:
        num1 = Math.floor(Math.random() * 900) + 100;
        break;
      default:
        console.error("Invalid digits");
        return;
    }

    const maxNum2 = Math.pow(10, digits) - 1;
    num2 = Math.floor(Math.random() * Math.min(limit + 1, maxNum2 + 1));

    if (type === mathC.SUBTRACTION_TYPE || type === mathC.DIVISION_TYPE) {
      if (num1 < num2) {
        [num1, num2] = [num2, num1];
      }
    }

    // Calculate answer based on type
    switch (type) {
      case mathC.ADDITION_TYPE:
        answer = num1 + num2;
        break;
      case mathC.SUBTRACTION_TYPE:
        answer = num1 - num2;
        break;
      case mathC.MULTIPLICATION_TYPE:
        answer = num1 * num2;
        break;
      default:
        break;
    }
  }

  // Return the problem with the correct answer included
  return { type, num1, num2, answer };
};

// Additional helper functions
export const generateRandomTime = () => {
  let hours = Math.floor(Math.random() * 12);
  const minutes = Math.floor(Math.random() * 12) * 5;

  // Convert hour `0` to `12`
  hours = hours === 0 ? 12 : hours;

  return `${hours}:${minutes.toString().padStart(2, '0')}`;
};


export const generateTimesTableProblem = (factor, limit) => {
  let num1 = getRandomIntInclusive(0, limit);
  let num2 = factor;
  let answer = num1 * num2;

  return {
    type: mathC.MULTIPLICATION_TYPE,
    num1,
    num2,
    answer,
  };
};

export const generateClockProblem = (time, type) => {
  return {
    type,
    time,
    answer: time,
  };
};

// Random integer helper
function getRandomIntInclusive(min, max) {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled);  // Inclusive min and max
}

export const getProblemTypesAndNames = (limit, factor) => ({
  oneDigitAddition: { text: "1-Digit Addition", digits: 1, type: mathC.ADDITION_TYPE },
  twoDigitAddition: { text: "2-Digit Addition", digits: 2, type: mathC.ADDITION_TYPE },
  threeDigitAddition: { text: "3-Digit Addition", digits: 3, type: mathC.ADDITION_TYPE },
  oneDigitSubtraction: { text: "1-Digit Subtraction", digits: 1, type: mathC.SUBTRACTION_TYPE },
  twoDigitSubtraction: { text: "2-Digit Subtraction", digits: 2, type: mathC.SUBTRACTION_TYPE },
  threeDigitSubtraction: { text: "3-Digit Subtraction", digits: 3, type: mathC.SUBTRACTION_TYPE },
  oneDigitMultiplication: { text: "1-Digit Multiplication", digits: 1, type: mathC.MULTIPLICATION_TYPE },
  twoDigitMultiplication: { text: "2-Digit Multiplication", digits: 2, type: mathC.MULTIPLICATION_TYPE },
  threeDigitMultiplication: { text: "3-Digit Multiplication", digits: 3, type: mathC.MULTIPLICATION_TYPE },
  division: { text: "Division", digits: 1, type: mathC.DIVISION_TYPE },
  timesTable: { text: "Times Table Problem", factor, limit, type: mathC.MULTIPLICATION_TYPE },
  clockProblem: { text: "Clock Problems", digits: 0, type: mathC.CLOCK_TYPE }
});