// src/analytics.js
import ReactGA from 'react-ga4';

// Initialize Google Analytics with your Measurement ID
export const initGA = () => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('G-Z0D52848N8');
  }
};

// Track a specific page view
export const logPageView = (pagePath) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.send({ hitType: 'pageview', page: pagePath });
  }
};

// Track custom events
export const logEvent = (category, action, label = '', value = null) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.event({ category, action, label, value });
  }
};