import React, { useState, useEffect, useRef } from 'react';
import QandA from './QandA';
import QandAModeSelector from './QandAModeSelector';
import { logEvent } from '../analytics';
import Hero from './Hero';
import Footer from './Footer';
import FeedbackWidget from './FeedBackWidget';
import Snackbar from './Snackbar';
import { initGA, logPageView } from '../analytics';

const InteractiveSession = ({ problemTypes, problemTypesAndNames, limit, factor }) => {
    const [modeConfig, setModeConfig] = useState(null);
    const [snackbar, setSnackbar] = useState({ isVisible: false, message: '', type: '' });
    const qandARef = useRef(null);

    // Function to show the Snackbar
    const showSnackbar = (message, type) => {
        setSnackbar({ isVisible: true, message, type });
    };

    // Function to close the Snackbar
    const handleCloseSnackbar = () => {
        setSnackbar({ isVisible: false, message: '', type: '' });
    };

    /* load google analytics */
    useEffect(() => {
        initGA();
        logPageView(window.location.pathname + window.location.search);
    }, []);

    // Handle mode selection and problem generation
    const handleModeSelection = (config) => {
        setModeConfig(config);

        logEvent('Interactive Session', 'Select Session Mode', config.mode);
        // Log detailed configuration
        logEvent('Interactive Session', 'Mode Configuration', {
            mode: config.mode,
            problemCount: config.problemCount || null,
            timeLimit: config.timeLimit || null,
            printProblemCount: config.printProblemCount || null,
            problemType: config.problemType || null,
        });

    };

    // Return to mode selection within InteractiveSession without navigating
    const handleBackToModeSelector = () => {
        setModeConfig(null);
    };

    const handleEndSession = () => {
        // This function can reset the session or perform other actions
        // use this to persist session logs and results after a run
        // and to reset
        // can also be used to display session logs, but currently we're doing that 
        // in the QandA page. if we ever change that, here's a way to do that
        //setModeConfig(null);
        logEvent('Interactive Mode', 'Session End', modeConfig.mode);
    };

    const gatherTroubleshootingData = async () => {
        try {
            const qandAData = await qandARef.current?.gatherTroubleshootingData();
            const lastError = window.lastError || 'No recent errors';
            const userAgent = navigator.userAgent;
            const windowSize = `${window.innerWidth}x${window.innerHeight}`;

            return {
                ...qandAData,
                lastError,
                userAgent,
                windowSize,
                timestamp: new Date().toISOString(),
            };
        } catch (error) {
            console.error('Error gathering troubleshooting data:', error);
            return { error: 'Failed to collect troubleshooting data.' };
        }
    };

    return (
        <div className="w-full flex flex-col items-center p-4 m-4 border-4 space-y-4">
            {/* Back Button */}
            {modeConfig && (
                <button
                    onClick={handleBackToModeSelector}
                    className="no-print back-button text-white bg-green-500 px-5 py-2 rounded-full shadow-md hover:bg-green-600"
                >
                    ← Back
                </button>
            )}

            {/* Hero Component */}
            {!modeConfig && (
                <div className="w-full mb-4 hero-container">
                    <Hero theme={'kidified'} />
                </div>
            )}

            <FeedbackWidget
                theme={'adultified'}
                showSnackbar={showSnackbar}
                getTroubleshootingData={gatherTroubleshootingData}
            />

            {/* Conditional Rendering for Mode Config */}
            {modeConfig ? (
                <div className="w-full mt-4">
                    <QandA
                        ref={qandARef}
                        modeConfig={modeConfig}
                        limit={limit}
                        factor={factor}
                        problemTypes={problemTypes}
                        problemTypesAndNames={problemTypesAndNames}
                        onEndSession={handleEndSession}
                    />
                </div>
            ) : (
                <div className="flex flex-col items-center w-full mt-4">
                    <h2 className="text-xl font-bold mb-4 mt-4">Select a Mode</h2>
                    <QandAModeSelector onSelectMode={handleModeSelection} />
                </div>
            )}
            <Snackbar
                message={snackbar?.message}
                type={snackbar?.type}
                isVisible={snackbar?.isVisible}
                onClose={handleCloseSnackbar}
            />
            <Footer theme={'adultified'} />
        </div>
    );
};

export default InteractiveSession;